import { SearchOutlined } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useGetContent, useTablePagination } from '../../../hooks';
import { IModerator } from '../../../models/moderator';
import { listModerator } from '../../../services/moderator';
import { formatCpf } from '../../../utils';
import Badges from '../../Badges';
import Button from '../../Button';
import { FlexContainer } from '../../FlexContainer';
import Table from '../../Table';
import SimpleModal from '..';
import * as S from './styles';

type ModeratorModalProps = {
  isOpen: boolean;
  onCloseChange: () => void;
  handleSelected: (moderator) => void;
  handleRemoveSelected: (moderatorId) => void;
  classModerator: string[] | [];
};

type RowType = { label: string; value: string };

export default function ModeratorModal({
  handleRemoveSelected,
  handleSelected,
  isOpen,
  onCloseChange,
  classModerator,
}: ModeratorModalProps) {
  const [rows, setRows] = useState<IModerator[] | []>([]);
  const [selectedRows, setSelectedRows] = useState<RowType[] | []>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const { loading, getVariedContents } = useGetContent(listModerator);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const params = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page,
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row.name,
      },
      {
        headerName: 'CPF',
        field: 'cpf',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => formatCpf(row.cpf),
      },
      {
        headerName: 'E-mail',
        field: 'email',
        minWidth: 150,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row.email,
      },
      {
        headerName: 'Selecionar',
        field: '',
        minWidth: 160,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <FlexContainer justifyContent="center" width="100%">
              <Button
                id={cellValues.row.moderator_id}
                variant="contained"
                title="Selecionar Item"
                disabled={
                  !!selectedRows.find(
                    moderator =>
                      moderator.value === cellValues.row.moderator_id,
                  )
                }
                onClick={e => {
                  e.currentTarget.disabled = !e.currentTarget.disabled;
                  const moderator = {
                    label: cellValues.row.name,
                    value: cellValues.row.moderator_id,
                  };
                  setSelectedRows(prevState => [...prevState, moderator]);
                  handleSelected(cellValues.row);
                }}
              >
                Selecionar
              </Button>
            </FlexContainer>
          );
        },
      },
    ],
    [selectedRows],
  );

  useEffect(() => {
    getVariedContents(setRows, setRowCount, params);
  }, [paginationModel.pageSize, paginationModel.page]);

  useEffect(() => {
    if (classModerator.length) {
      classModerator.map(moderatorId => {
        const row = rows.find(row => row.moderator_id === moderatorId);
        row !== undefined &&
          setSelectedRows(prevState => [
            ...prevState,
            { label: row?.name, value: row?.moderator_id },
          ]);
      });
    }
  }, [rows]);

  const tableProps = {
    columns,
    rows,
    keyId: 'moderator_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [5, 10, 25],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    // TODO: ADICIONAR ORDENAÇÃO
    // onSortModelChange: model => handleSort(model),
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Lista de Moderadores"
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem">
          <S.Label htmlFor="search">
            Busca por Nome
            <S.Input
              id="search"
              ref={inputRef}
              placeholder="Busque pelo nome do moderador"
            />
          </S.Label>

          <S.SearchButton
            type="button"
            title="Fazer a busca"
            onClick={() => {
              const search = inputRef.current?.value;
              const searchParams = params;

              if (search !== undefined && search.length) {
                searchParams['search'] = search;
              }

              getVariedContents(setRows, setRowCount, searchParams);
            }}
          >
            <SearchOutlined fontSize="inherit" />
          </S.SearchButton>
        </FlexContainer>

        <FlexContainer
          flexWrap="wrap"
          gap="6px"
          width="100%"
          style={{ minWidth: '44px' }}
        >
          {selectedRows.map(row => (
            <Badges
              key={row.value}
              handleRemove={() => {
                handleRemoveSelected(row.value);
                setSelectedRows(prevState =>
                  prevState.filter(item => item.value !== row.value),
                );
              }}
            >
              {row.label}
            </Badges>
          ))}
        </FlexContainer>

        <Table {...tableProps} width="90vw" height="100%" disableColumnMenu />
      </S.Wrapper>
    </SimpleModal>
  );
}

import styled, { css } from 'styled-components';

type NavBarProps = Pick<NavButtonProps, 'isNavOpen'>;

const navBarModifiers = {
  true: () => css`
    width: 100%;
  `,
  false: () => css`
    width: 65px;
  `,
};

export const NavBar = styled.nav<NavBarProps>`
  ${({ theme, isNavOpen }) => css`
    background-color: ${theme.colors.white};
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-width: 0 1px 0 0;
    box-shadow: ${theme.shadow.default};
    display: block;
    height: 100%;
    max-width: 250px;
    position: relative;
    transition: ${theme.transition.default};

    bottom: 0;
    top: 0;
    left: 0;

    ${navBarModifiers[isNavOpen.toString()]}
  `}
`;

export const LogoContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.primary};
    display: flex;
    justify-content: center;
    height: 4.875rem;
    position: relative;
    width: 100%;
    padding: 0.5rem;

    img {
      max-width: 100%;
      max-height: 2.5rem;
    }
  `}
`;

export const Menus = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

type NavButtonProps = {
  isNavOpen: boolean;
};

const navButtonModiffiers = {
  true: () => css`
    svg {
      transform: scaleX(-1);
    }
  `,
  false: () => css`
    svg {
      transform: scaleX(1);
    }
  `,
};

export const NavButton = styled.button<NavButtonProps>`
  ${({ theme, isNavOpen }) => css`
    align-items: center;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: ${theme.shadow.default};
    background-color: ${theme.colors.sebrae_play_pink};
    color: ${theme.colors.white};
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 28px;
    transition: ${theme.transition.default};
    width: 28px;
    z-index: 1;

    top: 64px;
    right: -14px;

    ${navButtonModiffiers[isNavOpen.toString()]};
  `}
`;

export const Container = styled.div`
  ${() => css`
    overflow-y: auto;
    height: 100%;
  `}
`;

import httpClient from '../http-client';
import {
  IBodyConsumption,
  IConsumption,
  IContentUsersReportResponse,
  IFiltersCourseAndSeries,
  IListConsumption,
  IMetaData,
  IProgressAverageByContent,
} from '../models/consumption';

type getListConsumptionResp = {
  data: IListConsumption[];
  metaData: IMetaData;
};

export const getListConsumption = async params => {
  const list = await httpClient.get<getListConsumptionResp>(
    '/consumption/admin/list',
    { params },
  );

  return list.data;
};

export const getConsumptionById = async (consumptionId: string) => {
  const consumption = await httpClient
    .get<IConsumption>(`/consumption/${consumptionId}`)
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.response.data.message);
    });

  return consumption;
};

export const updateConsumptionBody = async (
  consumptionId: string,
  body: IBodyConsumption,
) => {
  const consumption = await httpClient
    .patch<IConsumption>(`/consumption/interaction/body/${consumptionId}`, body)
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.response.data.message);
    });

  return consumption;
};

export const updateCourseConsumptionBody = async (
  consumptionId: string,
  body: IBodyConsumption,
) => {
  const consumption = await httpClient
    .patch<IConsumption>(
      `/consumption//interaction/body-course/${consumptionId}`,
      body,
    )
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.response.data.message);
    });

  return consumption;
};

export const resendConsumption = async (consumptionId: string) => {
  const consumption = await httpClient
    .patch<IConsumption>(`/consumption/interaction/resend/${consumptionId}`)
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.response.data.message);
    });

  return consumption;
};

export interface IResponseReport extends IContentUsersReportResponse {
  message: string;
}

export const getUsersByCourseAndSerie = async (
  params?: IFiltersCourseAndSeries,
): Promise<IResponseReport> => {
  const response = await httpClient.get('/reports/content-user', { params });

  return response.data;
};

export const getCities = async (): Promise<string[]> => {
  const response = await httpClient.get('/users/cities');

  return response.data;
};

export const getCnaes = async (): Promise<string[]> => {
  const response = await httpClient.get('/companies/cnaes');

  return response.data;
};

export const getUfs = async (): Promise<string[]> => {
  const response = await httpClient.get('/users/ufs');

  return response.data;
};

export const getProgressAverage = async (
  params?: IFiltersCourseAndSeries,
): Promise<{ data: IProgressAverageByContent[] | []; metaData: IMetaData }> => {
  const response = await httpClient.get(
    '/reports/content-user/progress-average',
    { params },
  );

  return response.data;
};

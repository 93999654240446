import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

import SideBar from '../SideBar';
import * as S from './styles';

function Layout(): React.ReactElement {
  return (
    <S.Wrapper>
      <S.LeftContent>
        <SideBar />
      </S.LeftContent>

      <S.Main>
        <Outlet />
      </S.Main>
    </S.Wrapper>
  );
}

export default memo(Layout);

import { zodResolver } from '@hookform/resolvers/zod';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { format, parse } from 'date-fns';
import { compareAsc } from 'date-fns';
import lodash from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as z from 'zod';

import {
  classesSchema,
  stageFourSchema,
  stageOneSchema,
  stageThreeSchema,
  stageTwoSchema,
} from '../../../../common/validations/syncCourseValidation';
import Button from '../../../../components/Button';
import { FlexContainer } from '../../../../components/FlexContainer';
import ModeratorModal from '../../../../components/SimpleModal/ModeratorModal';
import Tabs from '../../../../components/Tabs/';
import { convertStringToDate } from '../../../../helpers/convertStringToDate';
import convertTimeToMilliseconds from '../../../../helpers/convertTimeToMilliseconds';
import { IModerator } from '../../../../models/moderator';
import { ICasses, ICourseContent, IEvent } from '../../../../models/SyncCourse';
import {
  createSyncCourse,
  getMethodologyByCode,
  getSyncCourseStepFour,
  getSyncCourseStepOne,
  getSyncCourseStepThree,
  getSyncCourseStepTwo,
  updateSyncCourseStepFour,
  updateSyncCourseStepOne,
  updateSyncCourseStepThree,
  updateSyncCourseStepTwo,
} from '../../../../services/syncCourse';
import SyncCourseStageFour from '../../../../templates/SyncCourseCreateEditTemplate/SyncCourseStageFour';
import SyncCourseStageOne from '../../../../templates/SyncCourseCreateEditTemplate/SyncCourseStageOne';
import SyncCourseStageThree from '../../../../templates/SyncCourseCreateEditTemplate/SyncCourseStageThree';
import SyncCourseStageTwo from '../../../../templates/SyncCourseCreateEditTemplate/SyncCourseStageTwo';
import { dealWithTitleChange, removeTraitPoints } from '../../../../utils';

type RowType = {
  price: string;
  start: Date | string;
  finish: Date | string;
} & Pick<IEvent, 'code' | 'status' | 'target' | 'vacancies' | 'instructor'>;
type ClassesType = z.infer<typeof classesSchema>;

export type StageOneFormValues = z.infer<typeof stageOneSchema>;
export type StageTwoFormValues = z.infer<typeof stageTwoSchema>;
export type StageThreeFormValues = z.infer<typeof stageThreeSchema>;
export type StageFourFormValues = z.infer<typeof stageFourSchema>;

export default function SyncCourseCreateAndEdit(): React.ReactElement {
  const [rows, setRows] = useState<RowType[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<Partial<ICourseContent>>(
    {} as ICourseContent,
  );
  const [activeStep, setActiveStep] = useState(0);
  const [stepCompleted, setStepCompleted] = useState([true]);
  const [allHookFormState, setAllHookFormState] = useState<any[]>([]);
  const [classModerator, setClassModerator] = useState<string[]>([]);
  const [classSelected, setClassSelected] = useState<string>();
  const [courseClass, setCourseClass] = useState<ICasses[] | undefined>();

  const stageOneMethods = useForm<StageOneFormValues>({
    resolver: zodResolver(stageOneSchema),
  });
  const stageTwoMethods = useForm<StageTwoFormValues>({
    resolver: zodResolver(stageTwoSchema),
  });
  const stageThreeMethods = useForm<StageThreeFormValues>({
    resolver: zodResolver(stageThreeSchema),
  });
  const stageFourMethods = useForm<StageFourFormValues>({
    resolver: zodResolver(stageFourSchema),
  });
  const navigate = useNavigate();
  const location = useLocation();

  const courseId = new URLSearchParams(location.search).get('id');
  const view = new URLSearchParams(location.search).get('view');

  const createHeaders = (
    headerName: string,
    field: string,
    minWidth?: number,
    flex?: number,
  ) => {
    return {
      headerName,
      field,
      minWidth,
      flex: flex ? flex : 1,
      sortable: false,
    };
  };

  const handleModeratorModal = (classId: string) => {
    setClassSelected(classId);
    setIsOpen(!isOpen);
  };

  const columnHeaders = useMemo(
    () => [
      createHeaders('Código da Turma', 'code', 150),
      createHeaders('Status', 'status', 100),
      createHeaders('Público alvo', 'target', 300, 2),
      createHeaders('Preço', 'price', 110),
      createHeaders('Vagas', 'vacancies', 60),
      createHeaders('Instrutor', 'instructor', 200),
      {
        headerName: 'Data Início',
        field: 'start',
        minWidth: 150,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(cellValues?.value, 'dd/MM/yyyy HH:mm')}
          </p>
        ),
      },
      {
        headerName: 'Data Fim',
        field: 'finish',
        minWidth: 150,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(cellValues?.value, 'dd/MM/yyyy HH:mm')}
          </p>
        ),
      },
      {
        headerName: 'Gerenciar Moderador',
        field: 'actions',
        minWidth: 180,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="center"
            width="100%"
          >
            <Button
              onClick={() => {
                const { getValues } = stageTwoMethods;
                const classes = getValues('classes');
                const classSelected = classes?.find(
                  item => item.sebrae_code === cellValues.id,
                );
                const moderators =
                  classSelected?.moderators !== undefined
                    ? classSelected?.moderators
                    : [];

                setClassModerator(moderators);
                handleModeratorModal(cellValues.id);
              }}
              icon={<AddCircleOutlineOutlined fontSize="inherit" />}
              color="secondary"
              title="Adicionar / Remover Moderador"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const initialStateTable: GridInitialStateCommunity = {
    sorting: {
      sortModel: [{ field: 'start', sort: 'desc' }],
    },
  };

  const handleInvalidCodeError = () => {
    toast.error('O Código Da Metodologia deve ser número.');
  };

  const handleDownload = (textContent: string) => {
    const element = document.createElement('a');
    const file = new Blob([textContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'mensagem.txt';
    element.click();
  };

  const handleMethodologyFetchError = error => {
    console.error(error);

    Swal.fire({
      icon: 'error',
      iconColor: '#f5365c',
      title: 'Erro ao buscar a metodologia!',
      html: `O erro está vindo do barramento!<br>${error.message || error}`,
      showDenyButton: true,
      confirmButtonColor: '#5e72e4',
      denyButtonText: 'Baixar log',
      denyButtonAriaLabel:
        'Faz o download do arquivo de log com os códigos que estão com problemas',
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        htmlContainer: 'message-error-methodology modal-html-container',
        denyButton: 'btn-download-log',
        confirmButton: 'btn-ok',
        title: 'modal-title',
      },
    }).then(({ isDenied }) => {
      if (isDenied) {
        const text = (error.message || error)
          .replaceAll('<br>', '\n')
          .replaceAll('</b>', '\n')
          .replaceAll('<b>', '');

        handleDownload(text);
      }
    });
  };

  const getMethodology = async (
    methodologyCode: string,
    savedClasses?: ICasses[],
  ) => {
    setLoading(true);
    const { setValue } = stageTwoMethods;

    const invalidCodeRegex = /[^0-9]/g;
    if (invalidCodeRegex.test(methodologyCode)) {
      handleInvalidCodeError();
      return;
    }

    try {
      const methodology = await getMethodologyByCode(methodologyCode);
      const formatString = 'dd/MM/yyyy HH:mm';
      const price = Number(methodology.preco_maximo_venda);
      const isFree = price === 0 ? true : false;
      const duration = convertTimeToMilliseconds(
        `${methodology.carga_horaria}:00`,
      );

      setValue('name', methodology.name);
      setValue('workload', `${methodology.carga_horaria}:00`);
      setValue('duration', duration);
      setValue('theme', methodology.theme.name);
      setValue('price', price);
      setValue('is_free', isFree);

      if (!methodology.events || !methodology.events.length) {
        throw new Error('Essa metodologia não tem turma cadastrada.');
      }

      const classes: ClassesType[] = methodology.events.map(event => {
        const {
          name,
          description,
          vacancies,
          price,
          duration,
          start,
          finish,
          code,
          schedule,
          cpf_instructor,
          email_instructor,
          status,
          instructor,
        } = event;

        const meetings = schedule.map(item => ({
          start_at: parse(item.start, formatString, new Date()),
          finish_at: parse(item.finish, formatString, new Date()),
          sebrae_id: item.id.toString(),
        }));
        const instructorSlug = dealWithTitleChange(instructor);

        return {
          name,
          price,
          description,
          duration: convertTimeToMilliseconds(duration),
          start_at: parse(start, formatString, new Date()),
          finish_at: parse(finish, formatString, new Date()),
          sebrae_code: code,
          vacancies,
          meetings,
          instructor_cpf: removeTraitPoints(cpf_instructor) || '',
          instructor_email: email_instructor,
          instructor_name: instructor,
          instructor_slug: instructorSlug,
          instructor_is_email_cpf_blocked: true,
          status: status.toLocaleLowerCase(),
          moderators: [],
        };
      });

      const classesMap = new Map(classes.map(cls => [cls.sebrae_code, cls]));

      savedClasses?.forEach(savedClasse => {
        const matchingClass = classesMap.get(savedClasse.sebrae_code);

        if (matchingClass) {
          const moderatorIds = savedClasse.moderators.map(
            moderator => moderator.moderator_id,
          );
          matchingClass.moderators = moderatorIds;
        }
      });

      const updatedClasses = Array.from(classesMap.values());
      setValue('classes', updatedClasses);

      const rows = methodology.events.map(
        ({
          code,
          status,
          target,
          price,
          vacancies,
          instructor,
          start,
          finish,
        }) => {
          const dtStart = parse(start, formatString, new Date());
          const dtFinish = parse(finish, formatString, new Date());
          return {
            code,
            status,
            target,
            price: price.toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            }),
            vacancies,
            instructor,
            start: dtStart,
            finish: dtFinish,
          };
        },
      );

      setRows([...rows]);
      Swal.fire({
        icon: 'info',
        title: 'Busca de Metodologia',
        text: 'Sua busca pela metodologia foi concluída com sucesso.',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
    } catch (error) {
      handleMethodologyFetchError(error);
    } finally {
      setLoading(false);
    }
  };

  const tableProps = {
    columns: columnHeaders,
    rows: rows,
    keyId: 'code',
    initialState: initialStateTable,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    loading,
  };

  const getStepOne = useCallback(
    async (id: string, cancelToken) => {
      const { setValue } = stageOneMethods;
      setLoading(true);
      try {
        const resp = await getSyncCourseStepOne(id, cancelToken);

        if (resp.status === 'published') {
          setStepCompleted([true, true, true, true]);
        }

        setValue('status', resp.status);
        setValue('content_id', resp.content_id);
        setValue('page_title', resp.page_title);
        setValue('slug', resp.slug);
        setValue('title', resp.title);
        setValue('subtitle', resp.subtitle);
        setValue('resume', resp.resume);
        setValue('description', resp.description);
        setValue('image_name', resp.image_name);
        setValue('image_toltip', resp.image_toltip);
        setValue('image_file', resp.image_file);
        setValue('image_file_id', resp.image_file_id);
        setValue(
          'days_to_complete',
          !resp.days_to_complete ? '1' : resp.days_to_complete.toString(),
        );
        setValue('requester_id', resp.requester.requester_id);
        setValue('requester', {
          label: resp.requester.name,
          value: resp.requester.requester_id,
        });
        setValue(
          'company_units',
          resp.company_units.map(item => ({
            label: item.name,
            value: item.company_unit_id,
          })),
        );
        setValue(
          'categories',
          resp.categories.map(category => category.category_id),
        );

        const {
          categories,
          metatags,
          duration,
          reference,
          file_toltip,
          allow_download,
          file_name,
          type,
        } = resp;
        setContent({
          categories: categories,
          metatags: metatags,
          duration,
          reference,
          file_name,
          file_toltip,
          allow_download,
          type,
        });

        const featuredStartAt = convertStringToDate(resp.featured_start_at);
        const featuredEndAt = convertStringToDate(resp.featured_end_at);
        setValue('featured_start_at', featuredStartAt);
        setValue('featured_end_at', featuredEndAt);
      } catch (error: any) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          iconColor: '#f5365c',
          title: 'Erro ao buscar o cursos!',
          text: error.message || error,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [courseId],
  );

  const getStepTwo = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const { setValue } = stageTwoMethods;
        const resp = await getSyncCourseStepTwo(id);
        setCourseClass(resp.classes);

        setValue('content_id', resp.content_id);
        setValue('sebrae_code', resp.sebrae_code);
        setValue('status', resp.status);
      } catch (error: any) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          iconColor: '#f5365c',
          title: 'Erro ao buscar o cursos!',
          text: error.message || error,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [courseId],
  );

  const getStepThree = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const { setValue } = stageThreeMethods;
        const resp = await getSyncCourseStepThree(id);

        setValue('content_id', resp.content_id);
        setValue(
          'complement_contents',
          resp.complement_contents.map(content => content.content_id),
        );
        setValue('knowledges', resp.knowledges);
        setValue(
          'modules_sync',
          resp.modules_sync.map(module => module.module_sync_id),
        );
        setValue('status', resp.status);

        setContent(prevState => ({
          ...prevState,
          modules_sync: resp.modules_sync,
          complement_contents: resp.complement_contents,
        }));
      } catch (error: any) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          iconColor: '#f5365c',
          title: 'Erro ao buscar o cursos!',
          text: error.message || error,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [courseId],
  );

  const getStepFour = async (id: string) => {
    setLoading(true);
    try {
      const { setValue } = stageFourMethods;
      const resp = await getSyncCourseStepFour(id);

      setValue('content_id', resp.content_id);
      setValue('tags', resp.tags);
      setValue('topics', resp.topics);
      setValue('status', resp.status);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o cursos!',
        text: error.message || error,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStepCompleted = i => {
    const updatedCompletedStep = stepCompleted;
    updatedCompletedStep[i] = true;

    handleStepSelect(i);
    setStepCompleted(updatedCompletedStep);
  };

  const handleSubmitStepOne = async (data: StageOneFormValues) => {
    if (
      data.featured_end_at !== null &&
      data.featured_end_at !== undefined &&
      data.featured_start_at !== null &&
      data.featured_start_at !== undefined
    ) {
      const highlightEndAtIsAfterStartAt = compareAsc(
        data.featured_end_at,
        data.featured_start_at,
      );

      if (highlightEndAtIsAfterStartAt === -1) {
        Swal.fire({
          icon: 'error',
          title: 'Erro na data de destaque',
          text: 'Data Fim Destaque não pode ser anterior a Data Início Destaque',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });

        return;
      }
    }

    if (data['image_file']) {
      delete data['image_file'];
    }

    delete data['highlight'];
    delete data['status'];

    const obj = {};
    const arr = allHookFormState;

    Object.assign(obj, data);
    delete obj['requester'];

    obj['company_units'] = data['company_units'].map(unit => unit.value);
    arr[0] = obj;

    setAllHookFormState(arr);

    if (!courseId || !data['content_id']) {
      Swal.fire({
        icon: 'info',
        title: 'Aviso',
        text: 'Esse curso será criado como Rascunho até todas as etapas estarem completas.',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      }).then(() => createSubmit(obj));
    } else {
      editSubmit(obj, 'one', 1);
    }
  };

  const handleSubmitStepTwo = async (data: StageTwoFormValues) => {
    delete data['name'];
    delete data['theme'];
    delete data['workload'];
    delete data['status'];

    const obj = {};
    const arr = allHookFormState;

    Object.assign(obj, data);
    arr[1] = obj;

    setAllHookFormState(arr);
    editSubmit(obj, 'two', 2);
  };

  const handleSubmitStepThree = async (data: StageThreeFormValues) => {
    const obj = {};
    const arr = allHookFormState;
    delete data['status'];

    Object.assign(obj, data);

    obj['knowledges'] = data['knowledges'].map(
      knowledge => knowledge.knowledge_id,
    );
    arr[2] = obj;

    setAllHookFormState(arr);
    editSubmit(obj, 'three', 3);
  };

  const handleSubmitStepFour = async (
    data: StageFourFormValues,
    status?: any,
  ) => {
    const obj = {};
    const arr = allHookFormState;

    Object.assign(obj, data);

    if (data['tags'] !== null && data['tags']?.length) {
      obj['tags'] = data['tags'].map(tag => tag.tag_id);
    }

    obj['topics'] = data['topics'].map(topic => topic.topic_id);
    obj['status'] = !status ? data['status'] : status;
    arr[3] = obj;

    setAllHookFormState(arr);
    editSubmit(obj, 'four');
  };

  const createSubmit = async data => {
    createSyncCourse(data)
      .then(resp => {
        stageOneMethods.setValue('content_id', resp['content_id']);
        stageTwoMethods.setValue('content_id', resp['content_id']);
        stageThreeMethods.setValue('content_id', resp['content_id']);
        stageFourMethods.setValue('content_id', resp['content_id']);

        Swal.fire({
          title: 'Curso criado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });

        handleStepCompleted(1);
      })
      .catch((error: any) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao criar o curso',
          text: error.message,
          iconColor: '#f5365c',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  const editSubmit = async (data, step, nextStep?) => {
    const id = data['content_id'];

    delete data['content_id'];
    delete data['image_file'];

    const editFunc = {
      one: updateSyncCourseStepOne,
      two: updateSyncCourseStepTwo,
      three: updateSyncCourseStepThree,
      four: updateSyncCourseStepFour,
    };
    try {
      await editFunc[step](id, data).then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });

        !nextStep ? navigate(-1) : handleStepCompleted(nextStep);
      });
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        iconColor: '#f5365c',
        text: error.message,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
    }
  };

  const onCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar as alterações?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const handleSubmitForCurrentStage = (currentStage: number) => {
    let methodToCall;

    switch (currentStage) {
      case 0:
        methodToCall = stageOneMethods.handleSubmit(
          data => handleSubmitStepOne(data),
          onError,
        );
        break;
      case 1:
        methodToCall = stageTwoMethods.handleSubmit(
          data => handleSubmitStepTwo(data),
          onError,
        );
        break;
      case 2:
        methodToCall = stageThreeMethods.handleSubmit(
          data => handleSubmitStepThree(data),
          onError,
        );
        break;
      case 3:
        methodToCall = stageFourMethods.handleSubmit(
          data => handleSubmitStepFour(data),
          onError,
        );
        break;
      default:
        methodToCall = null;
    }

    if (methodToCall) {
      methodToCall();
    }
  };

  const checkChange = n => {
    const allDirtyFields = [
      stageOneMethods.getValues(),
      stageTwoMethods.getValues(),
      stageThreeMethods.getValues(),
      stageFourMethods.getValues(),
    ];
    const prevStep = activeStep;
    const objStateKeys = !allHookFormState[prevStep]
      ? []
      : Object.keys(allHookFormState[prevStep]);
    const areDiff = objStateKeys.filter(
      key =>
        !lodash.isEqual(
          allHookFormState[prevStep][key],
          allDirtyFields[prevStep][key],
        ),
    );

    if (areDiff.length > 0) {
      Swal.fire({
        icon: 'info',
        title: '<h6>Houve alterações no formulário dessa etapa</h6>',
        html: 'Salve as alterçaões antes de trocar de etapa',
        showCancelButton: true,
        confirmButtonText: 'SALVAR E AVANÇAR',
        confirmButtonAriaLabel: 'Salvar e Avançar',
        cancelButtonText: 'CANCELAR',
        reverseButtons: true,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
          icon: 'modal-icon',
          cancelButton: 'btn-cancel',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          handleSubmitForCurrentStage(activeStep);
        }
      });
    } else {
      handleStepSelect(n);
    }
  };

  const handleStepSelect = (currentStep: number) => {
    setActiveStep(currentStep);
  };

  const handleModeratorSelected = (moderator: IModerator) => {
    const { getValues, setValue } = stageTwoMethods;
    const classes = getValues('classes');

    classes?.map(item => {
      if (item.sebrae_code === classSelected) {
        item['moderators'] === undefined
          ? (item['moderators'] = [moderator.moderator_id])
          : item['moderators'].push(moderator.moderator_id);
      }

      return item;
    });

    setValue('classes', classes);
  };

  const handleRemoveModerator = (moderatorId: string) => {
    const { getValues, setValue } = stageTwoMethods;
    const classes = getValues('classes');

    classes.map(item => {
      if (item.sebrae_code === classSelected && item.moderators !== undefined) {
        item.moderators = item.moderators.filter(id => id !== moderatorId);
      }

      return item;
    });

    setValue('classes', classes);
  };

  const TabsProps = {
    title: 'Curso Síncrono',
    labels: ['Etapa 1', 'Etapa 2', 'Etapa 3', 'Etapa 4'],
    activeStep,
    onSelect: checkChange,
    disabled: stepCompleted,
  };

  useEffect(() => {
    const { getValues } = stageTwoMethods;
    if (getValues('sebrae_code') !== undefined)
      getMethodology(getValues('sebrae_code'), courseClass);
  }, [courseClass]);

  return (
    <Tabs {...TabsProps}>
      <FormProvider key={'provider-stage-1'} {...stageOneMethods}>
        <SyncCourseStageOne
          view={view === 'true'}
          content={content}
          onSubmit={stageOneMethods.handleSubmit(handleSubmitStepOne, onError)}
          onCancel={onCancel}
          getFunc={getStepOne}
          handleCloneState={setAllHookFormState}
          isLoading={loading}
        />
      </FormProvider>

      <FormProvider key={'provider-stage-2'} {...stageTwoMethods}>
        <SyncCourseStageTwo
          view={view === 'true'}
          getMethodology={() => {
            getMethodology(
              stageTwoMethods.getValues('sebrae_code'),
              courseClass,
            );
          }}
          loading={loading}
          tableProps={tableProps}
          onSubmit={stageTwoMethods.handleSubmit(handleSubmitStepTwo, onError)}
          onCancel={onCancel}
          getFunc={getStepTwo}
          handleCloneState={setAllHookFormState}
        />
        {isOpen &&
          createPortal(
            <ModeratorModal
              isOpen={isOpen}
              onCloseChange={() => setIsOpen(!isOpen)}
              handleSelected={handleModeratorSelected}
              classModerator={classModerator}
              handleRemoveSelected={moderatorId =>
                handleRemoveModerator(moderatorId)
              }
            />,
            document.body,
          )}
      </FormProvider>

      <FormProvider key={'provider-stage-3'} {...stageThreeMethods}>
        <SyncCourseStageThree
          view={view === 'true'}
          content={content}
          onSubmit={stageThreeMethods.handleSubmit(
            handleSubmitStepThree,
            onError,
          )}
          onCancel={onCancel}
          getFunc={getStepThree}
          isLoading={loading}
          handleCloneState={setAllHookFormState}
        />
      </FormProvider>

      <FormProvider key={'provider-stage-4'} {...stageFourMethods}>
        <SyncCourseStageFour
          view={view === 'true'}
          onSubmit={stageFourMethods.handleSubmit(
            handleSubmitStepFour,
            onError,
          )}
          onCancel={onCancel}
          getFunc={getStepFour}
          isLoading={loading}
          handleCloneState={setAllHookFormState}
        />
      </FormProvider>
    </Tabs>
  );
}

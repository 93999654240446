import {
  DeleteOutlineOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../../components/Button/index';
import { FlexContainer } from '../../../components/FlexContainer';
import { TableProps } from '../../../components/Table';
import convertMilisecondsToHours from '../../../helpers/convertMilisecondsToHours';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import { ICourseContent } from '../../../models/SyncCourse';
import { deleteSyncCourse, ListSyncCourse } from '../../../services/syncCourse';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

export default function SyncCourse(): React.ReactElement {
  const [courses, setCourses] = useState<ICourseContent[] | []>([]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('PUBLISHED_AT');

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleParams } = useSearchContents();
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(ListSyncCourse);

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="sync">Curso Síncrono</span>,
    <span key="sync-course">Cursos</span>,
  ];

  const showCourse = useCallback(
    (id: string) => {
      navigate(`/synchronous/course/edit?id=${id}&view=true`);
    },

    [navigate],
  );

  const editCourse = useCallback(
    (id: string) => {
      navigate(`/synchronous/course/edit?id=${id}`);
    },

    [navigate],
  );

  const removeCourse = useCallback(async (id: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este curso?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#5e72e4',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteSyncCourse(id);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Curso excluído com sucesso!',
          });

          await getVariedContents(setCourses, setRowCount, {
            limit: paginationModel.pageSize,
            offset: 1,
            order_by_sort: orderBySort,
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir curso. ${error.message}`,
            iconColor: '#f5365c',
            confirmButtonColor: '#5e72e4',
          });
        }
      }
    });
  }, []);

  const columns = [
    {
      headerName: 'Título',
      field: 'title',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      renderCell: cellValues => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues.value}</p>
      ),
    },
    {
      headerName: 'Descrição',
      field: 'description',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      sortable: false,
      renderCell: cellValues => (
        <div
          style={{ whiteSpace: 'pre-wrap' }}
          className="description"
          dangerouslySetInnerHTML={{
            __html: cellValues.value,
          }}
        />
      ),
    },
    {
      headerName: 'Situação',
      field: 'status',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>
          {cellValues.value === 'hidden'
            ? 'Ocultado'
            : cellValues.value === 'draft'
            ? 'Rascunho'
            : 'Publicado'}
        </span>
      ),
    },
    {
      headerName: 'Duração',
      field: 'duration',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: cellValues => (
        <p style={{ whiteSpace: 'normal' }}>
          {convertMilisecondsToHours(cellValues.value)}
        </p>
      ),
    },
    {
      headerName: 'Criado',
      field: 'created_at',
      flex: 1,
      renderCell: cellValues => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Atualizado',
      field: 'updated_at',
      flex: 1,
      renderCell: cellValues => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Ações',
      field: 'actions',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: cellValues => (
        <FlexContainer gap="0.4rem" flexWrap="wrap" justifyContent="flex-start">
          <Button
            onClick={() => removeCourse(cellValues.id)}
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
          />
          <Button
            onClick={() => editCourse(cellValues.id)}
            icon={<EditOutlined fontSize="inherit" />}
            color="secondary"
            title="Editar"
          />
          <Button
            onClick={() => showCourse(cellValues.id)}
            icon={<VisibilityOutlined fontSize="inherit" />}
            color="secondary"
            title="Visualizar"
          />
        </FlexContainer>
      ),
    },
  ];

  const handleSearch = async () => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      inputRef.current?.value,
    );

    await getVariedContents(setCourses, setRowCount, params);
  };

  useEffect(() => {
    getVariedContents(setCourses, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  const tableProps: TableProps = {
    columns: columns,
    rows: courses,
    keyId: 'content_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
      }),
    disableColumnMenu: true,
  };

  return (
    <ContentTemplate
      key={'async-course-table'}
      pageName="cursos síncronos"
      linkToCreatePage="create"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      ref={inputRef}
      {...tableProps}
    />
  );
}

import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${() => css`
    display: flex;
    height: 100%;
    overflow: hidden;
  `}
`;

export const LeftContent = styled.div`
  flex: 0 0 auto;

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background: lightgray;
  }
`;

export const Main = styled.main`
  ${({ theme }) => css`
    flex: 1 1 auto;
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding: 20px 30px;
  `}
`;

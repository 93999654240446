import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { consumptionSchema } from '../../../common/validations/consumptionValidation';
import { courseConsumptionSchema } from '../../../common/validations/courseConsumptionValidation';
import { IBodyConsumption, IConsumption } from '../../../models/consumption';
import {
  getConsumptionById,
  updateConsumptionBody,
  updateCourseConsumptionBody,
} from '../../../services/consumption';
import ConsumptionEditTemplate from '../../../templates/ReportTemplates/ConsumptionEditTemplate';
import CourseConsumptionEditTemplate from '../../../templates/ReportTemplates/CourseConsumptionEditTemplate';
import { formatCnpj, formatCpf, removeTraitPoints } from '../../../utils';

type ConsumptionType = z.infer<typeof consumptionSchema>;
type CourseConsmptionType = z.infer<typeof courseConsumptionSchema>;

export default function ConsumptionRecordEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const otherConsumptionMethods = useForm<ConsumptionType>({
    resolver: zodResolver(consumptionSchema),
  });
  const courseConsumptionMethods = useForm<CourseConsmptionType>({
    resolver: zodResolver(courseConsumptionSchema),
  });
  const state = location.state as {
    consumption_id: string;
    content_type: string;
    filters: any;
  };
  const messageErro = 'Para editar é necessário um consumo válido';
  const locationStateOnlyFilters = {
    filters: state.filters,
  };

  const getCourseConsumption = async (consumption: IConsumption) => {
    if (!consumption.interaction) {
      throw new Error(messageErro);
    }

    const { setValue } = courseConsumptionMethods;

    if (consumption.body) {
      setValue('code_integration', consumption.body.code_integration);
      setValue('company', consumption.body.company);
      setValue('credential', consumption.body.credential);
      setValue('date_hour_start', new Date(consumption.body.date_hour_start));
      setValue('momento_id', consumption.body.momento_id?.toString());
      setValue('nome_realizacao', consumption.body.nome_realizacao);
      setValue('course_origin_id', consumption.body.origin_id?.toString());
      setValue(
        'portifolio_disponibilizacao_id',
        consumption.body.portifolio_disponibilizacao_id?.toString(),
      );
      setValue('theme_id', consumption.body.theme_id?.toString());
      setValue('tipo_realizacao', consumption.body.tipo_realizacao);
      setValue('title', consumption.body.title);
      setValue('type', consumption.body.type);
    }
  };

  const getOtherConsumption = async (consumption: IConsumption) => {
    if (!consumption.interaction || !consumption.body) {
      throw new Error(messageErro);
    }

    const { setValue } = otherConsumptionMethods;

    setValue('type', consumption.body.type);
    setValue('title', consumption.body.title);
    setValue('cod_acao', consumption.body.cod_acao);
    setValue('customer', formatCpf(consumption.body.customer || ''));
    setValue('company', formatCnpj(consumption.body?.company) || '');
    setValue('theme_id', consumption.body.theme_id.toString());
    setValue('origin_id', consumption.body.origin_id.toString());
    setValue('credential', consumption.body.credential);
    setValue('cod_projeto', consumption.body.cod_projeto);
    setValue('description', consumption.body.description);
    setValue('instrumento', consumption.body.instrumento);
    setValue('carga_horaria', consumption.body?.carga_horaria.toString() || '');
    setValue('date_hour_end', new Date(consumption.body.date_hour_end));
    setValue('cpf_responsavel', consumption.body.cpf_responsavel);
    setValue('date_hour_start', new Date(consumption.body.date_hour_start));
    setValue('nome_realizacao', consumption.body.nome_realizacao);
    setValue('code_integration', consumption.body.code_integration);
  };

  const getConsumption = async (consumptionId: string) => {
    try {
      const consumption = await getConsumptionById(consumptionId);

      if (state.content_type === 'course') {
        await getCourseConsumption(consumption);
      } else {
        await getOtherConsumption(consumption);
      }
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o consumo!',
        html: error.message || error,
        confirmButtonColor: '#5e72e4',
        buttonsStyling: false,
        customClass: {
          htmlContainer: 'message-error-methodology modal-html-container',
          denyButton: 'btn-download-log',
          confirmButton: 'btn-ok',
          title: 'modal-title',
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          navigate('/reports/consumption');
        }
      });
    }
  };

  const submitConsumption = async (data: ConsumptionType) => {
    try {
      const obj = {} as IBodyConsumption;
      if (!data['company']) delete data['company'];
      if (data['company']) data['company'] = removeTraitPoints(data['company']);

      delete data['customer'];
      Object.assign(obj, data);
      obj['origin_id'] = Number(data['origin_id']);
      obj['carga_horaria'] = Number(data['carga_horaria']);

      await updateConsumptionBody(state.consumption_id, obj).then(() => {
        toast.success('Consumo editado com sucesso!');
        navigate('/reports/consumption', { state: locationStateOnlyFilters });
      });
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao editar o consumo!',
        html: error.message || error,
        confirmButtonColor: '#5e72e4',
        buttonsStyling: false,
        customClass: {
          htmlContainer: 'message-error-methodology modal-html-container',
          denyButton: 'btn-download-log',
          confirmButton: 'btn-ok',
          title: 'modal-title',
        },
      });
    }
  };

  const submitCourseConsumption = async (data: CourseConsmptionType) => {
    try {
      const obj = {} as IBodyConsumption;
      if (!data['company']) delete data['company'];
      if (data['company']) data['company'] = removeTraitPoints(data['company']);

      Object.assign(obj, data);
      obj['momento_id'] = Number(data['momento_id']);
      obj['theme_id'] = Number(data['theme_id']);
      obj['origin_id'] = Number(data['course_origin_id']);
      obj['portifolio_disponibilizacao_id'] = Number(
        data['portifolio_disponibilizacao_id'],
      );

      delete obj['course_origin_id'];

      await updateCourseConsumptionBody(state.consumption_id, obj).then(() => {
        toast.success('Consumo editado com sucesso!');
        navigate('/reports/consumption', { state: locationStateOnlyFilters });
      });
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao editar o consumo!',
        html: error.message || error,
        confirmButtonColor: '#5e72e4',
        buttonsStyling: false,
        customClass: {
          htmlContainer: 'message-error-methodology modal-html-container',
          denyButton: 'btn-download-log',
          confirmButton: 'btn-ok',
          title: 'modal-title',
        },
      });
    }
  };

  const cancelSubmit = () =>
    navigate('/reports/consumption', { state: locationStateOnlyFilters });

  useEffect(() => {
    getConsumption(state.consumption_id);
  }, []);

  return (
    <>
      {state.content_type === 'course' ? (
        <FormProvider {...courseConsumptionMethods}>
          <CourseConsumptionEditTemplate
            submit={submitCourseConsumption}
            cancel={cancelSubmit}
          />
        </FormProvider>
      ) : (
        <FormProvider {...otherConsumptionMethods}>
          <ConsumptionEditTemplate
            submit={submitConsumption}
            cancel={cancelSubmit}
          />
        </FormProvider>
      )}
    </>
  );
}

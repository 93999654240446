import { z } from 'zod';

import { requiredMessage } from '../../constants/ErroMessages';
import { categorySchema } from './categoryValidation';
import { imageSchema } from './imageValidation';
import { knowledgesSchema } from './knowledgesValidation';
import { optionSelectSchema } from './optionSelectValidation';
import { TagSchema } from './tagValidation';
import { TopiSchema } from './topicValidation';

const dataSulSchema = z.object({
  id: z.number().optional(),
  cod_data: z.string().optional(),
  name: z.string().optional(),
  status: z.string().optional(),
  price: z.number().optional(),
  vacancies: z.number().optional(),
  descricao: z.string().optional(),
  publico_alvo: z.string().optional(),
  duration: z.any().optional(),
});

export const courseSchema = z
  .object({
    allow_download: z.boolean().nullable().optional(),
    authors: z.array(z.string()),
    categories: z
      .array(z.union([z.string(), categorySchema]), {
        required_error: requiredMessage,
      })
      .refine(val => {
        const isArrayOfStrings = val.every(item => typeof item === 'string');
        const isArrayOfObjects = val.every(
          item => categorySchema.safeParse(item).success,
        );

        return isArrayOfStrings || isArrayOfObjects;
      }),
    company_units: z
      .array(optionSelectSchema, {
        required_error: requiredMessage,
      })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
          });
        }
      }),
    complement_files: z.string().array().optional(),
    complement_contents: z.any().array().optional(),
    days_to_complete: z
      .string({ required_error: requiredMessage })
      .refine(value => Number(value) > 0, {
        message: 'Esse campo não aceita numero negativos',
      }),
    data_sul: dataSulSchema.optional(),
    description: z.string().nonempty(requiredMessage),
    duration: z.any().nullable().optional(),
    featured_start_at: z.date().nullable().optional(),
    featured_end_at: z.date().nullable().optional(),
    file_id: z.string().nullable().optional(),
    file_name: z.string().nullable().optional(),
    file_toltip: z.string().nullable().optional(),
    groups: z.array(optionSelectSchema).nullable().optional(),
    image_file: imageSchema.nullable().optional(),
    image_file_id: z.string().uuid().nullable().default(null).optional(),
    image_name: z.string().nullable().default(null).optional(),
    image_toltip: z.string().nullable().default(null).optional(),
    is_free: z.boolean({ required_error: requiredMessage }),
    knowledges: z
      .array(knowledgesSchema, { required_error: requiredMessage })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
          });
        }
      }),
    modules: z.array(z.string()).superRefine((val, ctx) => {
      if (!val.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredMessage,
        });
      }
    }),
    page_title: z
      .string()
      .nonempty(requiredMessage)
      .max(48, 'O título da página não poder conter mais que 48 caracteres'),
    price: z.number(),
    portfolio_id: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage)
      .regex(/([0-9])/),
    published_start_at: z.date().nullable(),
    published_end_at: z.date().nullable(),
    requester_id: z.string().uuid().nonempty(requiredMessage),
    requester: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: requiredMessage },
    ),
    reference: z.string().nullable().optional(),
    resume: z
      .string()
      .nonempty(requiredMessage)
      .max(155, 'O resumo não poder conter mais que 155 caracteres'),

    review_in: z.date({ required_error: requiredMessage }),
    sebrae_code: z.string().nonempty(requiredMessage),
    slug: z
      .string()
      .nonempty(requiredMessage)
      .max(65, 'O slug da página não poder conter mais que 65 caracteres'),
    subtitle: z
      .string()
      .nonempty(requiredMessage)
      .max(
        160,
        'O subtítulo da página não poder conter mais que 160 caracteres',
      ),
    tags: z
      .array(TagSchema, { required_error: requiredMessage })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
            path: ['tags'],
          });
        }
      }),
    title: z
      .string()
      .nonempty(requiredMessage)
      .max(70, 'O título do curso não poder conter mais que 70 caracteres'),
    topics: z
      .array(TopiSchema, { required_error: requiredMessage })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
            path: ['topics'],
          });
        }
      }),
    visibility: z.string({ required_error: requiredMessage }),
  })
  .superRefine((val, ctx) => {
    if (val.visibility === 'restricted' && !val.groups?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: requiredMessage,
        path: ['groups'],
      });
    }
  });

import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { courseSchema } from '../../../../common/validations/courseValidation';
import toast from '../../../../helpers/toast';
import useCourseSubmit from '../../../../hooks/useCourseSubmit';
import { IContent } from '../../../../models/content';
import { getContentById, updateContent } from '../../../../services/contents';
import { getSouthDate } from '../../../../services/courses';
import CourseTemplate from '../../../../templates/CourseTemplate';
import { groups } from '../../../../utils';

export type CourseFormValuesType = z.infer<typeof courseSchema>;

export default function CourseEdit() {
  const [content, setContent] = useState<IContent>();
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const methods = useForm<CourseFormValuesType>({
    resolver: zodResolver(courseSchema),
  });
  const navigate = useNavigate();

  const getContent = async (id: string | null): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate(-1));
      return;
    }

    setLoading(true);
    try {
      const { setValue } = methods;
      const resp = await getContentById(id);
      const groupsSelected = resp.groups
        ? groups.filter(group => resp.groups?.some(el => group.value === el))
        : null;

      setValue('allow_download', resp.allow_download);
      setValue(
        'company_units',
        resp.company_units.map(company => ({
          label: company.name,
          value: company.company_unit_id,
        })),
      );
      setValue(
        'days_to_complete',
        resp.days_to_complete !== null ? resp.days_to_complete.toString() : '1',
      );
      setValue('description', resp.description);
      setValue(
        'featured_start_at',
        resp.featured_start_at !== null
          ? new Date(resp.featured_start_at)
          : null,
      );
      setValue(
        'featured_end_at',
        resp.featured_end_at !== null ? new Date(resp.featured_end_at) : null,
      );
      setValue('groups', groupsSelected);
      setValue('file_id', resp.file_id || null);
      setValue('file_name', resp.file_name);
      setValue('file_toltip', resp.file_toltip || null);
      setValue('image_file', resp.image_file);
      setValue('image_file_id', resp.image_file_id);
      setValue('image_name', resp.image_name);
      setValue('image_toltip', resp.image_toltip);
      setValue('is_free', resp.is_free || false);
      setValue('knowledges', resp.knowledges);
      setValue('page_title', resp.page_title);
      setValue(
        'published_start_at',
        resp.published_start_at !== null
          ? new Date(resp.published_start_at)
          : null,
      );
      setValue('portfolio_id', resp.portfolio_id || '');
      setValue('price', resp.price);
      setValue(
        'published_end_at',
        resp.published_end_at !== null ? new Date(resp.published_end_at) : null,
      );
      setValue('requester_id', resp.requester_id);
      setValue('requester', {
        label: resp.requester.name,
        value: resp.requester.requester_id,
      });
      setValue('resume', resp.resume);
      setValue('review_in', new Date(resp.review_in));
      setValue('sebrae_code', resp.sebrae_code || '');
      setValue('slug', resp.slug);
      setValue('subtitle', resp.subtitle);
      setValue('tags', resp.tags);
      setValue('title', resp.title);
      setValue('topics', resp.topics);
      setValue('visibility', resp.visibility);

      !!resp.sebrae_code && getDataSulInfo(resp.sebrae_code);
      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getDataSulInfo = async (code: string) => {
    try {
      const response = await getSouthDate(code);
      const { setValue, setError, clearErrors } = methods;

      if (response.message) {
        setError('sebrae_code', { message: 'Código da turma inválida' });
        toast({
          message: response.message,
          type: 'warning',
        });
        return;
      }

      clearErrors('sebrae_code');
      setValue('price', response.price);
      setValue('duration', response.duration);

      setValue('data_sul.id', response.id);
      setValue('data_sul.cod_data', response.code);
      setValue('data_sul.name', response.name);
      setValue('data_sul.status', response.status);
      setValue('data_sul.price', response.price);
      setValue('data_sul.vacancies', response.vacancies);
      setValue('data_sul.descricao', response.methodology?.descricao);
      setValue('data_sul.publico_alvo', response.methodology?.publico_alvo);
      setValue('data_sul.duration', response.duration);

      toast({
        message: 'Informações do DataSul carregadas com sucesso!',
        type: 'success',
      });
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações do curso no DataSul!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    }
  };

  useEffect(() => {
    getContent(searchParams.get('id'));
  }, []);

  const submit = (data: CourseFormValuesType, status: string) => {
    if (
      status === 'published' &&
      (!data?.data_sul || (data?.data_sul && !data?.data_sul['id']))
    ) {
      methods.setError('sebrae_code', { message: 'Código da turma inválida' });
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações do curso no DataSul!',
        text: 'Código da turma inválida',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
      return;
    }

    const { obj } = useCourseSubmit({
      data,
      status,
      id: searchParams.get('id')!,
    });

    updateContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <CourseTemplate
        title="Edição do Curso"
        view={searchParams.get('view') === 'true'}
        content={content}
        isLoading={loading}
        submit={submit}
        getDataSulInfo={getDataSulInfo}
      />
    </FormProvider>
  );
}

export const progressStatus = [
  { label: 'Interesse', value: 'interested' },
  { label: 'Inscrito', value: 'subscribed' },
  { label: 'Iniciado', value: 'initiated' },
  { label: 'Em Progresso', value: 'in_progress' },
  { label: 'Concluído', value: 'finished' },
];

export const types = [
  { label: 'Cursos', value: 'course' },
  { label: 'Séries', value: 'series' },
];

export const genres = [
  { label: 'Masculino', value: 'masculino' },
  { label: 'Feminino', value: 'feminino' },
  { label: 'Outro', value: 'outro' },
];

export const userEducation = [
  { label: 'Não Disponível', value: 'notAvailable' },
  { label: 'Ensino Fundamental Completo', value: 'complete_elementary_school' },
  {
    label: 'Ensino Fundamental Incompleto',
    value: 'incomplete_elementary_school',
  },
  { label: 'Ensino Médio Completo', value: 'complete_high_school' },
  { label: 'Ensino Médio Incompleto', value: 'incomplete_high_school' },
  { label: 'Ensino Superior Completo', value: 'complete_higher_education' },
  { label: 'Ensino Superior Incompleto', value: 'incomplete_higher_education' },
  { label: 'Pós Graduação', value: 'postgraduate' },
];

export const companySize = [
  { label: 'Microempresa', value: 'micro' },
  { label: 'Empresa de Pequeno Porte', value: 'small' },
  { label: 'Empresa de Médio Porte', value: 'medium' },
  { label: 'Grande Empresa', value: 'large' },
  { label: 'Não Aplicável', value: 'not_applicable' },
  { label: 'Microempreendedor Individual ', value: 'microentrepreneur' },
  { label: 'Mega Empresa', value: 'mega' },
];

export const companyType = [
  { label: 'Artesão', value: 'artisan' },
  { label: 'Associação', value: 'association' },
  { label: 'Candidato a Cargo Político Eletivo', value: 'candidate' },
  { label: 'Consórcio', value: 'consortium' },
  { label: 'Cooperativa', value: 'cooperative' },
  { label: 'Empresa (com CNPJ)', value: 'company' },
  { label: 'Organização Internacional', value: 'international_organization' },
  {
    label: 'Outras organizações privadas sem fins lucrativos',
    value: 'others',
  },
  { label: 'Produtor Rural', value: 'rural_producer' },
  {
    label: 'Sem nat/Não classif/Não aplica/Não Inf/Inválido',
    value: 'not_applicable',
  },
  {
    label: 'Setor Público – Administração Direta',
    value: 'public_sector_direct',
  },
  {
    label: 'Setor Público – Administração Indireta',
    value: 'public_sector_indirect',
  },
  { label: 'Sindicato', value: 'union' },
  { label: 'Sociedade Anônima', value: 'anonymous_society' },
  { label: 'Sociedade de Economia Mista', value: 'mixed_economy_company' },
  {
    label: 'Cadastro Nacional de Pessoa Jurídica',
    value: 'legal_person_with_cnpj',
  },
];

import httpClient from '../http-client';
import { MetaDataProps, ReportFromResponse, ReportListFromResponse } from '../models/from-api-response/report';



const getReports = async () => {
  const reports = (
    await httpClient.get<ReportFromResponse>(
      `/reports`,
    )
  );

  return reports.data;
};

const getReportsList = async (params) => {
  const reports = (
    await httpClient.get<{data: ReportListFromResponse; metaData: MetaDataProps}>(
      `/reports/list`, {params}
    )
  ).data

  return reports;
};

export { getReports, getReportsList };
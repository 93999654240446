export default function useSearchContents() {
  const handleParams = (params, search?: string) => {
    if (search?.length) params['search'] = search;

    return params;
  };

  return {
    handleParams,
  };
}

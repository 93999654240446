import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1 0 27.81rem;
  padding-bottom: 1rem;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  min-height: 60px;
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.neutral_700};
    margin: 0 auto;
    padding-top: 1rem;
  `}
`;

import React, { ButtonHTMLAttributes } from 'react';

import * as S from './styles';

type ColorType =
  | 'default'
  | 'secondary'
  | 'blue'
  | 'success'
  | 'warning'
  | 'error'
  | 'violet'
  | 'teal'
  | 'pink'
  | 'orange'
  | 'neutral'
  | 'info';

type SelectColorType = {
  [key in ColorType]: any;
};

export type ButtonType = {
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'outlined' | 'contained';
  disabled?: boolean;
  color?: ColorType;
  fullWidth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export type IconType = {
  children: React.ReactNode;
} & Pick<ButtonType, 'color' | 'variant'> &
  ButtonHTMLAttributes<HTMLButtonElement>;

function Standard({ startIcon, children, endIcon, ...props }: ButtonType) {
  return (
    <S.ButtonRoot {...props}>
      {startIcon && startIcon}
      {children && <span>{children}</span>}
      {endIcon && endIcon}
    </S.ButtonRoot>
  );
}

function Icon({ children, ...props }: IconType) {
  return <S.ButtonIconRoot {...props}>{children}</S.ButtonIconRoot>;
}

export default function Button({
  children,
  endIcon,
  startIcon,
  icon,
  disabled,
  color = 'default',
  ...props
}: ButtonType) {
  const getColor: SelectColorType = {
    success: 'green',
    error: 'red',
    warning: 'yellow',
    violet: 'violet',
    teal: 'teal',
    pink: 'pink',
    info: 'info',
    default: 'default',
    secondary: 'secondary',
    blue: 'blue',
    orange: 'orange',
    neutral: 'neutral',
  };

  const standardProps = {
    children,
    endIcon,
    startIcon,
    disabled,
    color: getColor[color],
    type: props.type ? props.type : 'button',
    ...props,
  };

  const iconProps = {
    color: getColor[color],
    type: props.type ? props.type : 'button',
    ...props,
  };

  return (
    <>
      {icon && <Icon {...iconProps}>{icon}</Icon>}
      {children && <Standard {...standardProps} />}
    </>
  );
}

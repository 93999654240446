import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '../../../components/Button/index';
import CustomLoading from '../../../components/CustomLoading';
import CustomRowsOverlay from '../../../components/CustomRowsOverlay';
import { FlexContainer } from '../../../components/FlexContainer';
import Card from '../../../components/FormComponent/Card';
import TextField from '../../../components/FormComponent/TextField';
import Table, { TableProps } from '../../../components/Table';
import * as S from './styles';

type SyncCourseStageTwoProps = {
  view: boolean;
  loading: boolean;
  tableProps: TableProps;
  getMethodology: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  getFunc: (id: string) => Promise<void>;
  handleCloneState: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function SyncCourseStageTwo({
  view,
  getMethodology,
  loading,
  tableProps,
  onSubmit,
  onCancel,
  getFunc,
  handleCloneState,
}: SyncCourseStageTwoProps) {
  const courseId = new URLSearchParams(location.search).get('id');
  const { getValues } = useFormContext();

  useEffect(() => {
    if (courseId)
      getFunc(courseId).then(() => {
        handleCloneState(state => {
          const arr = state;
          arr[1] = getValues();

          return arr;
        });
      });
  }, []);

  const components = {
    NoRowsOverlay: () => <CustomRowsOverlay title="Sem eventos" />,
  };

  return (
    <S.Form>
      <FlexContainer gap="1.5rem" alignItems="flex-end">
        <TextField
          label="Código da Metodologia"
          name="sebrae_code"
          flex="1 0 14rem"
          disabled={view}
        />
        <Button
          type="button"
          variant="contained"
          onClick={getMethodology}
          disabled={view}
        >
          Atualizar
        </Button>
      </FlexContainer>

      <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
        <Card title="Campos do Barramento" isExpandable>
          {loading ? (
            <CustomLoading />
          ) : (
            <FlexContainer gap="0.875rem" flexWrap="wrap">
              <TextField label="Nome" name="name" flex="1 0 12rem" disabled />
              <TextField
                label="Carga Horária"
                name="workload"
                flex="1 0 12rem"
                disabled
              />
              <TextField label="Theme" name="theme" flex="1 0 12rem" disabled />
            </FlexContainer>
          )}
        </Card>

        <Card title="Agendas do Barramento" isExpandable>
          <Table {...tableProps} mode="client" components={components} />
        </Card>
      </FlexContainer>

      <FlexContainer gap="0.875rem" flexWrap="wrap" flexDirection="row-reverse">
        <Button type="button" variant="contained" onClick={() => onSubmit()}>
          Salvar e Avançar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

import {
  DeleteOutlineOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import { TableProps } from '../../../components/Table';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import {
  deleteLessonSync,
  getAllLessonSyncs,
} from '../../../services/lessonsSyncs';
import ContentTemplate from '../../../templates/ContentTemplate';

const LessonSync: React.FC = () => {
  const [lessonSync, setLessonSync] = useState([] as any[]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('created_at');

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleParams } = useSearchContents();
  const { getVariedContents, loading } = useGetContent(getAllLessonSyncs);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const showLessonSync = useCallback(
    (lesson_sync_id: string) => {
      navigate(`/synchronous/lesson/edit?id=${lesson_sync_id}&view=true`);
    },

    [navigate],
  );

  const editLessonSync = useCallback(
    (lesson_sync_id: any) => {
      navigate(`/synchronous/lesson/edit?id=${lesson_sync_id}`);
    },

    [navigate],
  );

  const removeLessonSync = useCallback(async (lesson_sync_id: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteLessonSync(lesson_sync_id);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluído com sucesso!',
          });

          await getVariedContents(setLessonSync, setRowCount, {
            limit: paginationModel.pageSize,
            offset: paginationModel.page,
            order_by_sort: orderBySort,
            order_by: orderBy,
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir conteúdo. ${
              error.response && error.response.status === 423
                ? 'A aula não pode ser excluído, pois tem módulos vinculados a ele.'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const columns = [
    {
      headerName: 'Título',
      field: 'title',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues.value}</p>
      ),
    },
    {
      headerName: 'Resumo',
      field: 'resume',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      sortable: false,
      renderCell: (cellValues: any) => (
        <div
          style={{ whiteSpace: 'pre-wrap' }}
          className="description"
          dangerouslySetInnerHTML={{
            __html: cellValues.value,
          }}
        />
      ),
    },

    {
      headerName: 'Situação',
      field: 'status',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>
          {cellValues.value === 'published' ? 'publicado' : 'Ocultado'}
        </span>
      ),
    },
    {
      headerName: 'Criado',
      field: 'created_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Atualizado',
      field: 'updated_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Ações',
      field: 'actions',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => (
        <FlexContainer gap="0.4rem" flexWrap="wrap" justifyContent="flex-start">
          <Button
            onClick={() => removeLessonSync(cellValues.id)}
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
          />
          <Button
            onClick={() => editLessonSync(cellValues.id)}
            icon={<EditOutlined fontSize="inherit" />}
            color="secondary"
            title="Editar"
          />
          <Button
            onClick={() => showLessonSync(cellValues.id)}
            icon={<VisibilityOutlined fontSize="inherit" />}
            color="secondary"
            title="Visualizar"
          />
        </FlexContainer>
      ),
    },
  ];

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="sync">Curso Síncrono</span>,
    <span key="sync-lesson">Aulas Síncronas</span>,
  ];

  const handleSearch = async () => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      inputRef.current?.value,
    );

    await getVariedContents(setLessonSync, setRowCount, params);
  };

  const handleSort = (sortModel: GridSortModel) => {
    if (!sortModel.length) return;

    changePage(0);

    setOrderBy(sortModel[0].field?.toLowerCase());
    setOrderBySort(sortModel[0].sort?.toUpperCase() || 'DESC');
  };

  useEffect(() => {
    getVariedContents(setLessonSync, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBy, orderBySort]);

  const tableProps: TableProps = {
    columns: columns,
    rows: lessonSync,
    keyId: 'lesson_sync_id',
    loading,
    disableColumnMenu: true,
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    onSortModelChange: model => handleSort(model),
  };

  return (
    <ContentTemplate
      key={'sync-lesson-table'}
      pageName="aulas síncronas"
      linkToCreatePage="/synchronous/lesson/create"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      ref={inputRef}
      {...tableProps}
    />
  );
};

export default LessonSync;

import {
  DeleteOutlineOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import { TableProps } from '../../../components/Table';
import convertMilisecondsToHours from '../../../helpers/convertMilisecondsToHours';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import { ILesson } from '../../../models/lesson';
import { deleteEpisode, getAllEpisode } from '../../../services/episodeSerie';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

const EpisodePage: React.FC = () => {
  const [lessons, setLessons] = useState([] as ILesson[]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('created_at');

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleParams } = useSearchContents();
  const { getVariedContents, loading } = useGetContent(getAllEpisode);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const removeLesson = useCallback(async (lessonId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este episódio?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteEpisode(lessonId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Episódio excluído com sucesso!',
          });

          await getVariedContents(setLessons, setRowCount, {
            limit: paginationModel.pageSize,
            offset: 1,
            order_by_sort: orderBySort,
            order_by: orderBy,
          });
        } catch (error: any) {
          Swal.fire({
            title: error.response.data.message,
            icon: 'error',
          });
        }
      }
    });
  }, []);

  const showLesson = useCallback(
    (contentId: string) => {
      navigate(`/Episodes/createAndEditEpisodes?id=${contentId}&view=true`);
    },

    [navigate],
  );

  const editLesson = useCallback(
    (contentId: string) => {
      navigate(`/Episodes/createAndEditEpisodes?id=${contentId}`);
    },

    [navigate],
  );

  const columns = [
    {
      headerName: 'Título',
      field: 'title',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues.value}</p>
      ),
    },
    {
      headerName: 'Descrição',
      field: 'description',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      sortable: false,
      renderCell: (cellValues: any) => (
        <div
          style={{ whiteSpace: 'pre-wrap' }}
          className="description"
          dangerouslySetInnerHTML={{
            __html: cellValues.value,
          }}
        />
      ),
    },
    {
      headerName: 'Duração',
      field: 'duration',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => {
        if (cellValues.value !== 0) {
          return (
            <p style={{ whiteSpace: 'normal' }}>
              {convertMilisecondsToHours(cellValues.value)}
            </p>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                color: '#fb6340',
              }}
            >
              <RunningWithErrorsIcon />
              <span>Em processamento</span>
            </div>
          );
        }
      },
    },
    {
      headerName: 'Situação',
      field: 'status',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>
          {cellValues.value === 'hidden'
            ? 'Ocultado'
            : cellValues.value === 'draft'
            ? 'Rascunho'
            : 'Publicado'}
        </span>
      ),
    },
    {
      headerName: 'Criado',
      field: 'created_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Atualizado',
      field: 'updated_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Ações',
      field: 'actions',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => (
        <FlexContainer gap="0.4rem" flexWrap="wrap" justifyContent="flex-start">
          <Button
            onClick={() => removeLesson(cellValues.id)}
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
          />
          <Button
            onClick={() => editLesson(cellValues.id)}
            icon={<EditOutlined fontSize="inherit" />}
            color="secondary"
            title="Editar"
          />
          <Button
            onClick={() => showLesson(cellValues.id)}
            icon={<VisibilityOutlined fontSize="inherit" />}
            color="secondary"
            title="Visualizar"
          />
        </FlexContainer>
      ),
    },
  ];

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="serie">Séries</span>,
    <span key="serie-episode">Episódios</span>,
  ];

  const handleSearch = async () => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      inputRef.current?.value,
    );

    await getVariedContents(setLessons, setRowCount, params);
  };

  useEffect(() => {
    getVariedContents(setLessons, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  const tableProps: TableProps = {
    columns: columns,
    rows: lessons,
    keyId: 'lesson_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    disableColumnMenu: true,
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
  };

  return (
    <ContentTemplate
      key={'episode-table'}
      pageName="episódios"
      linkToCreatePage="/episodes/createAndEditEpisodes"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      ref={inputRef}
      {...tableProps}
    />
  );
};

export default EpisodePage;

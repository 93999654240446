import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { courseSchema } from '../../../../common/validations/courseValidation';
import toast from '../../../../helpers/toast';
import useCourseSubmit from '../../../../hooks/useCourseSubmit';
import { createContent } from '../../../../services/contents';
import { getSouthDate } from '../../../../services/courses';
import CourseTemplate from '../../../../templates/CourseTemplate';
import { CourseFormValuesType } from '../CourseEdit';

export default function CourseCreate() {
  const [searchParams] = useSearchParams();
  const methods = useForm<CourseFormValuesType>({
    resolver: zodResolver(courseSchema),
    defaultValues: { is_free: false, review_in: new Date(), groups: [] },
  });
  const navigate = useNavigate();

  const getDataSulInfo = useCallback(async (code: string) => {
    try {
      const response = await getSouthDate(code);
      const { setValue, setError, clearErrors } = methods;

      if (response.message) {
        setError('sebrae_code', { message: 'Código da turma inválida' });
        toast({
          message: response.message,
          type: 'warning',
        });
        return;
      }

      clearErrors('sebrae_code');
      setValue('price', response.price);
      setValue('duration', response.duration);

      setValue('data_sul.id', response.id);
      setValue('data_sul.cod_data', response.code);
      setValue('data_sul.name', response.name);
      setValue('data_sul.status', response.status);
      setValue('data_sul.price', response.price);
      setValue('data_sul.vacancies', response.vacancies);
      setValue('data_sul.descricao', response.methodology?.descricao);
      setValue('data_sul.publico_alvo', response.methodology?.publico_alvo);
      setValue('data_sul.duration', response.duration);

      toast({
        message: 'Informações do DataSul carregadas com sucesso!',
        type: 'success',
      });
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações do curso no DataSul!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    }
  }, []);

  const submit = (data: CourseFormValuesType, status: string) => {
    if (
      status === 'published' &&
      (!data?.data_sul || (data?.data_sul && !data?.data_sul['id']))
    ) {
      methods.setError('sebrae_code', { message: 'Código da turma inválida' });
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações do curso no DataSul!',
        text: 'Código da turma inválida',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
      return;
    }

    const { obj } = useCourseSubmit({
      data,
      status,
    });

    if (obj['content_id']) delete obj['content_id'];

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <CourseTemplate
        title="Criação do Curso"
        view={searchParams.get('view') === 'true'}
        submit={submit}
        getDataSulInfo={getDataSulInfo}
      />
    </FormProvider>
  );
}

import React from 'react';

import * as S from './styles';

type CustomLoading = S.WrapperProps;

export default function CustomLoading(props: CustomLoading) {
  return (
    <S.Wrapper {...props}>
      <div className="spinner"></div>
    </S.Wrapper>
  );
}

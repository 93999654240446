import { useState } from 'react';
import Swal from 'sweetalert2';

import { MetaDataProps } from '../models/from-api-response/report';
import { getAdminContentList } from '../services/contents';

type HookProps = (params) => Promise<{ data: any; metaData: MetaDataProps }>;

export default function useGetContent(callback?: HookProps) {
  const [loading, setLoading] = useState(false);

  const getAllContents = async (
    types: string[],
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    setRowCountState: React.Dispatch<React.SetStateAction<number>>,
    page: number,
    pageSize: number,
    data?: any,
  ) => {
    setLoading(true);
    const params = data
      ? { ...data, limit: pageSize, offset: page }
      : { limit: pageSize, offset: page, types };
    const localContents = await getAdminContentList(params);

    setState(localContents.data);
    setRowCountState(localContents.metaData.total);
    setLoading(false);
  };

  const getVariedContents = async (
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    setRowCountState?: React.Dispatch<React.SetStateAction<number>>,
    otherFilters?: any,
  ) => {
    if (!callback) return;

    try {
      setLoading(true);
      const localContents = await callback(otherFilters);

      setState(localContents.data);
      setRowCountState && setRowCountState(localContents.metaData.total!);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações!',
        text: error.message || error,
        confirmButtonColor: '#5e72e4',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    getAllContents,
    getVariedContents,
    loading,
  };
}

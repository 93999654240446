import { GridSortModel } from '@mui/x-data-grid';
import { differenceInYears, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import TextField from '../../../components/FormComponent/TextField';
import SimpleModal from '../../../components/SimpleModal';
import Table, { TableProps } from '../../../components/Table';
import { progressStatus } from '../../../constants/courseAndSerieSelect';
import { useGetReport, useTablePagination } from '../../../hooks';
import {
  IContentUsersReportResponse,
  IFiltersCourseAndSeries,
  IProgressAverageByContent,
} from '../../../models/consumption';
import {
  getCities,
  getCnaes,
  getProgressAverage,
  getUfs,
  getUsersByCourseAndSerie,
} from '../../../services/consumption';
import theme from '../../../styles/theme';
import CourseAndSerieConsumptiomTemplate, {
  SelectValuesType,
} from '../../../templates/ReportsTemplates/CourseAndSerieConsumptiomTemplate';

type OrderBy =
  | 'name'
  | 'title'
  | 'progress_status'
  | 'progress'
  | 'city'
  | 'gender'
  | 'age'
  | undefined;
type OrderBySort = 'DESC' | 'ASC' | undefined;
export type ChartLabelsType = {
  genderLabels: string[] | [];
  ageLabels: string[] | [];
  educationLabels: string[] | [];
  companySizesLabels: string[] | [];
  companyTypeLabels: string[] | [];
  cnaeLabels: string[] | [];
  companyAgeLabels: string[] | [];
};

const DEFAULT_VALUES = {
  progress_average_start: 0,
  progress_average_end: 1,
  progress_start: 0,
  progress_end: 1,
  email: '',
};

export default function CourseAndSerieConsumptiom() {
  const [consumptionData, setConsumptionData] = useState(
    {} as IContentUsersReportResponse,
  );
  const [progressAverageByContent, setProgressAverageByContent] = useState<
    IProgressAverageByContent[] | []
  >([]);
  const [progressAveragePagination, setProgressAveragePagination] = useState({
    offset: 1,
    limit: 20,
    total: 0,
  });
  const [selectValues, setSelectValues] = useState<SelectValuesType>({
    ufs: [],
    cities: [],
    cnaes: [],
  });
  const [orderBySort, setOrderBySort] = useState<OrderBySort | string>('DESC');
  const [orderBy, setOrderBy] = useState<OrderBy | string>();
  const [chartLabels, setChartLabels] = useState<ChartLabelsType>({
    genderLabels: [],
    ageLabels: [],
    educationLabels: [],
    companySizesLabels: [],
    companyTypeLabels: [],
    cnaeLabels: [],
    companyAgeLabels: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getCourseAndSerieConsumption, loading } = useGetReport(
    getUsersByCourseAndSerie,
  );

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const progressAverageParams = {
    offset: 1,
    limit: 20,
    order_by: 'progress_average',
    order_by_sort: 'DESC',
    progress_average_start: 0,
    progress_average_end: 1,
    progress_start: 0,
    progress_end: 1,
  };

  const getCitiesApi = useCallback(async () => {
    const resp = await getCities();
    const data = [...new Set(resp)];
    const manipulatedData = data.map(city => ({ label: city, value: city }));
    setSelectValues(oldState => ({ ...oldState, cities: manipulatedData }));
  }, []);

  const getCnaesApi = useCallback(async () => {
    const resp = await getCnaes();
    const manipulatedData = resp.map(cnae => ({ label: cnae, value: cnae }));
    setSelectValues(oldState => ({ ...oldState, cnaes: manipulatedData }));
  }, []);

  const getUfsApi = useCallback(async () => {
    const resp = await getUfs();
    const data = [...new Set(resp)];
    const manipulatedData = data.map(convertsTheValuesToSelect);
    setSelectValues(oldState => ({ ...oldState, ufs: manipulatedData }));
  }, []);

  const manipulatesData = data => {
    Object.keys(data).forEach(item => {
      if (data[item] === null) {
        delete data[item];
      }
    });

    if (data['title'] === undefined || !data['title'].length)
      delete data['title'];
    if (data['email'].length === 0) delete data['email'];
    if (data['type']) data['type'] = [data['type']];
    if (data['contents'])
      data['contents'] = data['contents'].map(content => content.value);

    return data;
  };

  const submit = async data => {
    changePage(0);

    const cleanData = manipulatesData(data);
    const params = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    };
    Object.assign(params, cleanData);

    await handleProgressAverage({ ...cleanData, ...progressAverageParams });
    await getCourseAndSerieConsumption(
      setConsumptionData,
      setRowCount,
      params,
    ).then(handleResponseSubmit);
  };

  const exportException = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      iconColor: '#f5365c',
      title: 'Erro ao solicitar o relatório',
      text: error.message || error,
      buttonsStyling: false,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
    });
  };
  const submitExport = async data => {
    const cleanData = manipulatesData(data);
    const params = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    };
    Object.assign(params, cleanData);

    await getUsersByCourseAndSerie(params)
      .then(resp => {
        Swal.fire({
          icon: 'info',
          iconColor: theme.colors.info_400,
          text: resp?.message,
          buttonsStyling: false,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
        });
      })
      .catch(error => exportException(error));
  };

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
    methods.setValue('email', '');
  };

  const clearForm = async () => {
    changePage(0);
    methods.reset(DEFAULT_VALUES);

    const cleanData = manipulatesData(methods.getValues());
    const params = {
      limit: paginationModel.pageSize,
      offset: 1,
      order_by_sort: orderBySort,
      order_by: orderBy,
    };
    Object.assign(params, cleanData);

    await getCourseAndSerieConsumption(
      setConsumptionData,
      setRowCount,
      params,
    ).then(handleResponseSubmit);
    await handleProgressAverage(progressAverageParams);
  };

  const handleSort = (sortModel: GridSortModel) => {
    changePage(0);
    setOrderBy(sortModel[0].field?.toLocaleLowerCase());
    setOrderBySort(sortModel[0].sort?.toUpperCase() || 'DESC');
  };

  const calculateAge = (birthDate: string) => {
    if (!birthDate) return null;

    const date = parseISO(birthDate);
    const age = differenceInYears(new Date(), date);
    return age;
  };

  const handleProgressAverage = async (params?: IFiltersCourseAndSeries) => {
    try {
      const response = await getProgressAverage(params);
      setProgressAverageByContent(response.data);
      setProgressAveragePagination({
        limit: response.metaData.limit,
        offset: response.metaData.offset,
        total: response.metaData.total,
      });
    } catch (error: any) {
      exportException(error);
    }
  };

  const handlePageSwitching = async (page: number) => {
    await handleProgressAverage({
      ...progressAverageParams,
      offset: page,
    });
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 200,
        flex: 2,
        valueGetter: ({ row }) => row?.user.name,
      },
      {
        headerName: 'E-mail',
        field: 'email',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row?.user.name,
      },
      {
        headerName: 'Telefone ',
        field: '',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row?.user.name,
      },
      {
        headerName: 'Idade',
        field: 'birth_date',
        maxWidth: 60,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => calculateAge(row?.user.birth_date),
      },
      {
        headerName: 'Gênero',
        field: 'gender',
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.user.gender,
      },
      {
        headerName: 'Cidade',
        field: 'city',
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.user.city,
      },
      {
        headerName: 'UF',
        field: 'uf',
        minWidth: 80,
        flex: 1,
        valueGetter: ({ row }) => row?.user.uf,
      },
      {
        headerName: 'Educação',
        field: 'education',
        minWidth: 200,
        flex: 2,
        valueGetter: ({ row }) => row?.content.education,
      },
      {
        headerName: 'Título',
        field: 'title',
        minWidth: 200,
        flex: 2,
        valueGetter: ({ row }) => row?.content.title,
      },
      {
        headerName: 'Status de Progresso',
        field: 'progress_status',
        minWidth: 160,
        flex: 1,
        valueGetter: ({ row }) =>
          progressStatus.find(item => item.value === row?.progress_status)
            ?.label,
      },
      {
        headerName: 'Progresso',
        field: 'progress',
        maxWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.progress,
      },
    ],
    [],
  );

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="reports">Relatórios</span>,
    <span key="report-general">Relatório de Cursos e Séries</span>,
  ];

  const tableProps: TableProps = {
    columns,
    rows: consumptionData?.contentUsersReport?.data || [],
    keyId: 'row_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: model => handleSort(model),
    disableColumnMenu: true,
    width: '100%',
    height: '100%',
  };

  const convertsTheValuesToSelect = (value: string) => {
    let data = value;

    if (value === 'notAvailable') data = 'Não Disponível';

    return { label: data, value: data };
  };

  const handleResponseSubmit = (resp: IContentUsersReportResponse) => {
    setChartLabels({
      ageLabels: resp.reportAge?.map(age => Object.keys(age)).flat(),
      genderLabels: resp.reportGender
        ?.map(gender => Object.keys(gender))
        .flat(),
      educationLabels: resp?.reportEducation
        .map(education => Object.keys(education))
        .flat(),
      companySizesLabels: resp?.reportCompanySize
        .map(size => Object.keys(size))
        .flat(),
      companyTypeLabels: resp?.reportCompanyType
        ?.map(type => Object.keys(type))
        .flat(),
      cnaeLabels: resp?.reportCnaes.map(cnae => Object.keys(cnae)).flat(),
      companyAgeLabels: resp?.reportCompanyAge
        .map(period => Object.keys(period))
        .flat(),
    });
  };

  useEffect(() => {
    getCourseAndSerieConsumption(setConsumptionData, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    });
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  useEffect(() => {
    getCitiesApi();
    getCnaesApi();
    getUfsApi();
    handleProgressAverage(progressAverageParams);

    getCourseAndSerieConsumption(setConsumptionData, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    }).then(handleResponseSubmit);
  }, []);

  return (
    <FormProvider {...methods}>
      <FlexContainer gap="1rem" flexDirection="column" width="100%">
        <CourseAndSerieConsumptiomTemplate
          breadcrumbs={breadcrumbs}
          submitFilters={submit}
          isLoading={false}
          chartLabels={chartLabels}
          selectValues={selectValues}
          handleClearForm={clearForm}
          progressAverageByContent={progressAverageByContent}
          reports={consumptionData}
          handlePageSwitching={handlePageSwitching}
          paginationCount={Math.round(
            progressAveragePagination.total / progressAveragePagination.limit,
          )}
        />

        <FlexContainer justifyContent="flex-end" width="100%">
          <Button type="button" variant="contained" onClick={handleOpenModal}>
            Exportar
          </Button>
        </FlexContainer>

        <Table {...tableProps} />
      </FlexContainer>

      {isOpen &&
        createPortal(
          <SimpleModal
            title="Solicitação de Relatório"
            isOpen={isOpen}
            onCloseChange={handleOpenModal}
          >
            <FlexContainer gap="10px" flexDirection="column" width="100%">
              <TextField
                label="Digite o e-mail que irá receber o relatório"
                name="email"
              />
              <Button
                type="button"
                disabled={!methods.watch('email')}
                variant="contained"
                onClick={() => {
                  methods.handleSubmit(submitExport, exportException)();
                  handleOpenModal();
                }}
              >
                Solicitar
              </Button>
            </FlexContainer>
          </SimpleModal>,
          document.body,
        )}
    </FormProvider>
  );
}

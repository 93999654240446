import { EditOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import { TableProps } from '../../../components/Table';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import { IModerator } from '../../../models/moderator';
import { listModerator } from '../../../services/moderator';
import ContentTemplate from '../../../templates/ContentTemplate';
import { formatCpf } from '../../../utils';

export default function ModeratorList() {
  const [moderators, setModerators] = useState([] as IModerator[]);

  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(listModerator);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleParams } = useSearchContents();

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="list-moderator">Lista de Moderadores</span>,
  ];

  const handleSearch = async () => {
    changePage(0);
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
      },
      inputRef.current?.value,
    );

    await getVariedContents(setModerators, setRowCount, params);
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row.name,
      },
      {
        headerName: 'CPF',
        field: 'cpf',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => formatCpf(row.cpf),
      },
      {
        headerName: 'E-mail',
        field: 'email',
        minWidth: 150,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row.email,
      },
      {
        headerName: 'Criado em',
        field: 'created_at',
        minWidth: 80,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          format(new Date(row.created_at), 'dd/MM/yyyy'),
      },
      {
        headerName: 'Criado em',
        field: 'updated_at',
        minWidth: 80,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          format(new Date(row.updated_at), 'dd/MM/yyyy'),
      },
      {
        headerName: 'Ações',
        field: 'actions',
        getRowHeight: 30,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Button
              onClick={() => {
                navigate(
                  `/users/moderator/edit/${cellValues.row['name'].replaceAll(
                    ' ',
                    '-',
                  )}`,
                  {
                    state: { moderator_id: cellValues.row['moderator_id'] },
                  },
                );
              }}
              icon={<EditOutlined fontSize="inherit" />}
              color="secondary"
              title="Editar informações do moderador"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const tableProps: TableProps = {
    columns: columns,
    rows: moderators,
    keyId: 'moderator_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    disableColumnMenu: true,
  };

  useEffect(() => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page,
      },
      inputRef.current?.value,
    );
    getVariedContents(setModerators, setRowCount, params);
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <ContentTemplate
      pageName="lista de moderadores"
      key={'moderator-list-table'}
      linkToCreatePage="/users/moderator/create"
      breadcrumbs={breadcrumbs}
      handleSearch={handleSearch}
      ref={inputRef}
      {...tableProps}
    />
  );
}

import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *{
      box-sizing: border-box;
      font-family: 'Inter', sans-serif;
      margin: 0;
      padding: 0;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      &::before,
      &::after {
        box-sizing: inherit;
      }
    }

    :root {
      --font-smallest: 12px;
      --font-small: 14px;
      --font-medium: 16px;
      --font-large: 18px;
      --font-larger: 28px;
      --default-title-color: #32325d;
      --default-dark-gray: #ced4da;
      --default-gray: #8898aa;
      --success-color: #2dce89;
      --primary-color: #354371;
      --danger-color: #f5365c;
      --info-color: #11cdef;
      --warning-color: #fb6340;
    }



  ${({ theme }) => css`
    html,
    body,
    #root {
      height: 100%;
    }

    *::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: white;
    }

    *::-webkit-scrollbar-thumb {
      background: lightgray;
    }

    body {
      //TODO: DESCOMENTAR ESSA LINHA APÓS ALTERAR TODOS OS ESTILOS
      background-color: ${theme.colors.white};
      color: ${theme.colors.neutral_200};
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.small};

      .swal2-icon.no-border {
        border: none;
      }

      //CROPPER
      .overlay {
        color: rgba(255, 255, 255, 0.9);
      }

      .advanced-cropper-simple-handler {
        background-color: rgb(0, 0, 0);
      }

      .advanced-cropper-simple-line {
        border-color: rgb(0, 0, 0);
      }
      // ESTILOS DO DROPDOWN DO MATERIAL UI DATAGRID
      .MuiMenuItem-root,
      .MuiInput-root,
      .MuiInputLabel-root {
        font-size: ${theme.font.sizes.small};
      }

      // ESTILOS DO DATAPICKER DO MATERIAL UI
      .MuiInputBase-input {
        padding: 10px 12px;
        -webkit-text-fill-color: ${theme.colors.neutral_600};
      }

      .MuiFormControl-root {
        width: 100%;
      }

      .Mui-disabled {
        background-color: ${theme.colors.gray_200};
      }

      .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: ${theme.colors.neutral_600};
      }

      .MuiButtonBase-root,
      .MuiButtonBase-root.Mui-disabled {
        color: ${theme.colors.neutral_600};
      }

      .MuiDataGrid-root {
        min-height: 500px;
        height: 100%;
      }

      .MuiDataGrid-filterFormColumnInput {
        flex: 1 0 6.25rem;
      }

      .MuiDataGrid-filterFormDeleteIcon {
        width: 1.25rem;
      }

      .PrivatePickersFadeTransitionGroup-root {
        font-size: 1.6rem;
      }

      .PrivatePickersYear-yearButton {
        font-size: 1rem;
      }

      .PrivatePickersSlideTransition-root {
        button {
          font-size: 1.2rem;
        }
      }

      // ESTILOS DO REACT SELECT
      /* .react-select-container,
      .basic-single {
        width: 100%;

        .react-select__control,
        .select__control {
          border: 0.1rem solid ${theme.colors.gray_300};
          border-radius: ${theme.border.radius};
          box-shadow: ${theme.shadow.form_input};
          height: calc(2.4rem + 1.8rem + 0.5rem);
          width: 100%;
        }

        .react-select__single-value {
          color: ${theme.colors.gray_600};
          font-size: ${theme.font.sizes.small};
        }
      } */

      // ESTILO DO MODAL
      .MuiModal-root {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.16);
      }

      .MuiDataGrid-columnHeaderTitle {
        color: ${theme.colors.neutral_700};
        font-size: ${theme.font.sizes.xsmall};
        font-weight: ${theme.font.semiBold};
      }

      .MuiDataGrid-cell {
        color: ${theme.colors.neutral_700};
        font-size: ${theme.font.sizes.xsmall};
      }

      // ESTILO DA MENSAGEM DE ERRO DO SWAL2 NA PÁGINA DE CURSO SINCRONO
      .message-error-methodology {
        max-height: 160px;
        overflow-y: auto;
      }

      .btn-download-log,
      .btn-ok {
        border-radius: 4px;
        cursor: pointer;
        font-size: ${theme.font.sizes.xsmall};
        padding: 0.5rem 1rem;
        font-weight: 600;
        margin: 0 0.25rem;

        :hover {
          background-image: linear-gradient(
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          );
        }

        :focus {
          outline: 0;
          box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
        }

        :active {
          background-image: linear-gradient(
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          );
        }
      }

      .btn-download-log {
        border: 1px solid ${theme.colors.neutral_400};
        background-color: ${theme.colors.white};
        color: ${theme.colors.neutral_700};
      }

      .btn-ok {
        border: none;
        background-color: ${theme.colors.default};
        color: ${theme.colors.white};
      }

      .btn-cancel {
        background-color: transparent;
        border: 1px solid;
        border-color: ${theme.colors.neutral_400};
        border-radius: 4px;
        color: ${theme.colors.neutral_700};
        cursor: pointer;
        font-size: clamp(0.75re, 0.3vw + 0.625rem, ${theme.font.sizes.xsmall});
        font-weight: ${theme.font.semiBold};
        margin: 0 0.25rem;
        min-height: 2rem;
        padding: 0.5rem 1rem;

        :hover {
          border-color: ${theme.colors.red_400};
          color: ${theme.colors.red_400};
        }

        :focus {
          box-shadow: 0 0 0 3px rgba(105, 111, 140, 0.5);
          outline: 0;
        }
      }

      .modal-title {
        font-size: 1.5rem;
      }

      .modal-html-container {
        font-size: 1rem;
      }

      .modal-icon {
        width: 4.5rem;
        height: 4.5rem;
      }

      //ESTILOS ANTIGOS QUE PRECISAM SER REVISTOS

      label.required::after {
        content: ' *';
        color: red;
      }

      .bold div span {
        color: #b9335a !important;
      }

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .spinner {
        border: ${theme.font.sizes.small} solid #f3f3f3;
        border-radius: 50%;
        border-top: ${theme.font.sizes.small} solid #3498db;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
      }

      .spinner.small {
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #3498db;
        width: 35px;
        height: 35px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      #global-modal {
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 4;
      }
    }
  `}
`;

export default GlobalStyles;

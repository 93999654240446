import {
  AddCircle,
  Article,
  Attachment,
  BlurLinear,
  Delete,
  DeleteForever,
  FileDownload,
  FilterAlt,
  InsertPhoto,
  Lyrics,
  ModeEdit,
  Save,
  Videocam,
  ViewList,
} from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardMedia,
  Checkbox,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import { IGetFilesParams } from '../../../common/interfaces/files';
// Helpers
import toast from '../../../helpers/toast';
import { useDownloadFile } from '../../../hooks';
import { getCategories } from '../../../services/categorization';
import {
  deleteFile,
  getFiles,
  searchFile,
  updateUploadFile,
  uploadFile,
} from '../../../services/files';
import CategoryDropDown from '../../CategoryDropDown';
import * as styles from './styles';

const Input = styled('input')({
  display: 'none',
});

export default function MainFile(props: any) {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [editingOn, setEditingOn] = React.useState<boolean>(false);
  const [allowDownload, setAllowDownload] = React.useState<boolean>(false);
  const [showPageFlip, setShowPageFlip] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileList>();
  const [fileName, setFileName] = React.useState<string>('');
  const [fileToolTip, setFileToolTip] = React.useState<string>('');
  const [fileType, setFileType] = React.useState<string>('');
  const [modalPreview, setModalPreview] = React.useState<
    string | ArrayBuffer
  >();
  const [category, setCategory] = React.useState<any[]>([]);
  const [hasFile, setHasFile] = React.useState<boolean>(false);
  const [skill, setSkill] = React.useState<any>();
  const [backCategories, setBackCategories] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [collectionModalOpen, setCollectionModalOpen] = React.useState(false);
  const [images, setImages] = React.useState<any[]>([]);
  const [imagePage, setImagePage] = React.useState(1);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [typeFilter, setTypeFilter] = React.useState<string>('');
  const [filter, setFilter] = React.useState<string>('');
  const [midiaItem, setMidiaItem] = React.useState<any>({});
  const [isEditing, setIsEditing] = React.useState(false);
  const [addList, setAddList] = React.useState<boolean>(false);

  const refSkill: any = useRef();
  refSkill.current = skill;

  React.useEffect(() => {
    getCategories().then((data: any) => {
      setSkill({ ...props, category: data.skills, setState: setCategory });
    });
  }, []);

  const { getValues, setValue, control, handleSubmit } = useForm();

  const fillFile = () => {
    const editingFile = props.content.file;
    const editing = props.content;
    props.setValue('allow_download', props.content.allow_download);
    setAllowDownload(props.content.allow_download);

    props.setValue('show_page_flip', props.content.show_page_flip);
    setShowPageFlip(props.content.show_page_flip);

    if (editingFile) {
      setEditingOn(true);
      setFileName(editingFile.original_name);
      setFileToolTip(editing.file_toltip);
      setFileType(editingFile.mime_type);
      props.setValue('file_id', editingFile.file_id);
      props.setValue('file_name', editing.file_name);
      props.setValue('file_toltip', editing.file_toltip);
      props.setValue('file', editingFile);
    }
  };

  const getFile = async (contentId: string) => {
    searchFile(contentId).then(data => {
      setFileName(data.original_name);
      setFileType(data.mime_type);
      props.setValue('file_id', data.file_id);
    });
  };

  const deleteFiles = async () => {
    props.setValue('file_id', null);
    props.setValue('file_name', null);
    props.setValue('file_toltip', null);
    props.setValue('file', null);
    setFiles(undefined);
    setFileName('');
    setHasFile(false);
  };

  useEffect(() => {
    if (props.content != null) {
      fillFile();
      if (props.content.file_id) {
        getFile(props.content.file_id);
      }
    }
  }, [props.content]);

  const handleSaveUpdate = (data: any) => {
    let body: any = {};
    const setCategories: any[] = [];

    if (category.length > 1) {
      let categoryCopy: any[] = [];
      categoryCopy = category;
      categoryCopy.forEach(el => setCategories.push(el.category_id));
    } else {
      toast({
        message: 'É necessário conter categoria com o código sebrae.',
        type: 'warning',
      });
    }

    body = { categories: setCategories, tooltip: getValues('file_toltip') };
    if (category.length > 1) {
      updateUploadFile(midiaItem.file_id, body).then(resp => {
        props.setValue('file_id', resp.file_id);

        Swal.fire({
          title: 'Mídia alterada com sucesso',
          icon: 'success',
        });
        setModalOpen(false);
        setCollectionModalOpen(false);
      });
      setModalOpen(false);
      setCollectionModalOpen(false);
    }
  };

  function onSubmit(data: any) {
    if (!fileName.length) {
      toast({
        message: 'É necessário dar um nome para o arquivo.',
        type: 'warning',
      });

      return;
    }

    if (!fileToolTip.length) {
      toast({
        message: 'É necessário escrever um Tooltip para o arquivo.',
        type: 'warning',
      });

      return;
    }

    if (isEditing) {
      handleSaveUpdate(data);
    } else {
      if (category.length > 1) {
        if (files !== undefined) {
          const file = files[0];
          const blob = file.slice(0, file.size, file.type);
          const newFile = new File(
            [blob],
            fileName + '.' + files[0].name.split('.')[1],
            { type: file.type },
          );

          const formData = new FormData();

          let categoryCopy: any[] = [];
          categoryCopy = category;
          categoryCopy.forEach(el =>
            formData.append('categories[]', el.category_id),
          );

          formData.append('file', newFile);
          formData.append('tooltip', fileToolTip);

          uploadFile(formData)
            .then(data => {
              if (props.type !== undefined && props.type === 'author') {
                props.setValue('file_id', data.file_id);
              } else {
                props.setValue('file_id', data.file_id);
                props.setValue('file_name', fileName);
                props.setValue('file_toltip', fileToolTip);
                props.setValue('allow_download', allowDownload);
                props.setValue('file', data);
              }
              setModalOpen(false);
              setHasFile(true);
            })
            .catch(error =>
              toast({
                message: error.response.data.message,
                type: 'error',
              }),
            );
        }
      } else {
        toast({
          message: 'É necessário escolher pelo menos uma categoria filha.',
          type: 'warning',
        });
      }
    }
  }

  React.useEffect(() => {
    if (modalOpen) {
      if (
        props.titleCompany === 'Foto' ||
        props.titleCompany === 'Logo Empresa'
      ) {
        setFileName('');
        setFileToolTip('');
      } else {
        if (!fileName && props.getValues('title') === undefined) {
          setFileName('');
          setFileToolTip('');
        } else {
          setFileName('Sebrae Minas - ' + props.getValues('title'));
          setFileToolTip('Sebrae Minas - ' + props.getValues('title'));
        }
      }
    }
  }, [modalOpen]);

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }

  const handleCollectionsClick = () => {
    const params: IGetFilesParams = {
      offset: imagePage,
      limit: 6,
      permission: props.permission,
    };

    setCollectionModalOpen(true);
    setLoading(true);
    getFiles(params).then((element: any) => {
      for (const item of element.data) {
        delete item['tooltip'];
      }

      setImages(element?.data);
    });

    setLoading(false);
  };

  function handleSearchFilter(type: string) {
    const params: IGetFilesParams = {
      offset: imagePage,
      permission: props.permission,
      limit: 6,
      type,
    };

    if (filter.length > 0) params['search'] = filter;
    if (type.length === 0) delete params['type'];

    setImagePage(1);
    setLoading(true);
    getFiles(params).then(images => {
      setImages(images?.data);
      setLoading(false);
    });
  }

  const deleteImage = async itemImage => {
    const params: IGetFilesParams = {
      offset: imagePage,
      limit: 6,
      permission: props.permission,
    };

    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover a mídia?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          deleteFile(itemImage.file_id);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Mídia excluída com sucesso!',
          }).then(async res => {
            if (res.isConfirmed) {
              setCollectionModalOpen(true);
              setLoading(true);
              getFiles(params).then((element: any) => {
                setImages(element?.data);
                setLoading(false);
              });
            }
          });
        } catch (error) {
          Swal.fire({
            title: 'Algo deu errado',
            icon: 'error',
          });
        }
      }
    });
  };

  const editCategory = useCallback(async (midiaItem: any) => {
    setMidiaItem(midiaItem);
    setIsEditing(true);
    setAddList(false);
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Deseja editar a mídia ' + midiaItem.original_name + ' ?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setFileName(midiaItem.original_name);
          setValue('file_name', midiaItem.original_name);
          setFileToolTip(midiaItem.tooltip);
          setModalPreview(midiaItem.reference);
          setFiles(midiaItem.reference);
          setSkill({
            ...refSkill.current,
            content: { categories: midiaItem.categories },
          });
          setModalOpen(true);
        } catch (e) {
          toast({
            message: 'algo deu errado',
            type: 'warning',
          });
        }
      }
    });
  }, []);

  const addCategory = useCallback(async (midiaItem: any) => {
    setMidiaItem(midiaItem);
    setIsEditing(true);
    setAddList(true);
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'A mídia não contém categoria vinculada, você deve adicionar.',
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setModalOpen(true);
          setFileName(midiaItem.original_name);
          setValue('file_name', midiaItem.original_name);
          setFileToolTip(midiaItem.tooltip);
          setModalPreview(midiaItem.reference);
          setFiles(midiaItem.reference);
        } catch (error) {
          toast({
            message: 'algo deu errado',
            type: 'warning',
          });
        }
      }
    });
  }, []);

  const cleanModalState = async () => {
    setFileToolTip('');
    setFileName('');
    setValue('file_name', null);
    setSkill({ ...refSkill.current, content: undefined });
    setModalPreview(undefined);
    setFiles(undefined);
  };

  const { downloadFile } = useDownloadFile();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        width: '100%',
      }}
    >
      {!props.isCompany && (
        <Typography variant="h4" style={{ marginTop: '1rem' }}>
          Arquivo Principal
        </Typography>
      )}
      <Box
        component="div"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
        }}
      >
        <styles.Label>{props.titleCompany}</styles.Label>
        <styles.InputText
          name="file"
          value={
            fileName || editingOn ? fileName : 'Nenhum arquivo selecionado'
          }
          disabled
          required
          id="outlined-required"
        />
      </Box>
      <div>
        <ButtonGroup
          style={{ boxShadow: '0px 0px' }}
          variant="contained"
          size="small"
        >
          <Button
            disabled={hasFile}
            onClick={handleCollectionsClick}
            startIcon={<ViewList />}
            style={{
              marginRight: 1,
            }}
          >
            <span>Listar</span>
          </Button>
          <Button
            disabled={props.view || hasFile}
            startIcon={<AddCircle />}
            onClick={() => setModalOpen(true)}
            style={{
              backgroundColor: '#008d4c',
              marginRight: 1,
            }}
          >
            <span> Adicionar novo</span>
          </Button>
          <Button
            disabled={props.view}
            onClick={() => {
              deleteFiles();
            }}
            startIcon={<Delete />}
            style={{
              backgroundColor: '#d73925',
              marginRight: 1,
            }}
          >
            <span>Deletar</span>
          </Button>
        </ButtonGroup>
      </div>
      {!props.isCompany && <></>}
      <Modal
        open={collectionModalOpen}
        onClose={() => setCollectionModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <styles.column>
            <AppBar
              position="static"
              style={{ marginBottom: '1rem' }}
              sx={{ bgcolor: '#1565c0', height: '3.5rem' }}
            >
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Mídia
                </Typography>
                <Button
                  onClick={() => setCollectionModalOpen(false)}
                  color="inherit"
                >
                  x
                </Button>
              </Toolbar>
            </AppBar>
            <div>
              <Button
                variant="contained"
                size="small"
                component="span"
                startIcon={<FilterAlt />}
                style={{
                  backgroundColor: '#1565c0',
                  marginLeft: '0.5rem',
                  marginBottom: '1rem',
                }}
                onClick={() =>
                  openFilter ? setOpenFilter(false) : setOpenFilter(true)
                }
              >
                <span>Filtros</span>
              </Button>
            </div>
            {openFilter && (
              <>
                <span style={{ marginLeft: '0.5rem' }}>Por tipo:</span>
                <Box
                  marginBottom={'0.5rem'}
                  marginLeft={'0.5rem'}
                  sx={{
                    display: 'flex',
                    '& > *': {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="outlined primary button group"
                    style={{ boxShadow: '0px 0px 0px' }}
                  >
                    <Button
                      startIcon={<Lyrics />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'audio' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('audio');
                        setTypeFilter('audio');
                      }}
                    >
                      Áudio
                    </Button>
                    <Button
                      startIcon={<Article />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'application' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('application');
                        setTypeFilter('application');
                      }}
                    >
                      Documentos
                    </Button>
                    <Button
                      startIcon={<InsertPhoto />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'image' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('image');
                        setTypeFilter('image');
                      }}
                    >
                      Imagem
                    </Button>
                    <Button
                      startIcon={<Videocam />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === 'video' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('video');
                        setTypeFilter('video');
                      }}
                    >
                      Vídeo
                    </Button>
                    <Button
                      startIcon={<BlurLinear />}
                      style={{
                        marginRight: 2,
                        backgroundColor:
                          typeFilter === '' ? '#3630b1' : '#8f8b8b',
                      }}
                      onClick={() => {
                        handleSearchFilter('');
                        setTypeFilter('');
                      }}
                    >
                      Todos
                    </Button>
                  </ButtonGroup>
                </Box>
                <span style={{ marginLeft: '0.5rem' }}>Por nome:</span>
                <styles.InputText
                  style={{ marginLeft: '0.5rem' }}
                  placeholder="Filtrar por nome"
                  onClick={event => {
                    event.stopPropagation();
                  }}
                  onChange={(event: any) => {
                    setLoading(true);
                    const params: IGetFilesParams = {
                      offset: imagePage,
                      permission: props.permission,
                      type: typeFilter,
                      limit: 6,
                    };
                    //TODO: ANALISAR ESSE IF
                    if (event.target.value !== '') {
                      params['search'] = event?.target?.value;
                      setFilter(event.target.value);
                      getFiles(params).then(images => {
                        setImages(images?.data);
                      });
                      setImagePage(0);
                    } else {
                      getFiles(params).then(images => {
                        setImages(images?.data);
                      });
                      setFilter('');
                    }
                    setLoading(false);
                  }}
                />
              </>
            )}

            {!loading ? (
              <>
                <styles.row>
                  {images.slice(0, images.length / 2).map((image, key) => {
                    return (
                      <Card
                        key={key}
                        sx={{ width: 150, margin: 'auto', marginTop: '0.5rem' }}
                        onClick={event => {
                          event.stopPropagation();
                          cleanModalState();
                          if (
                            image.mime_type === 'application/x-msdownload' ||
                            image.mime_type === 'application/x-msdos-program'
                          ) {
                            toast({
                              message:
                                'O tipo de arquivo não pode ser selecionado.',
                              type: 'warning',
                            });
                          } else {
                            if (image.categories.length === 0) {
                              addCategory(image);
                            } else {
                              setFileName(image.original_name);
                              setFileType(image.mime_type);
                              props.setValue('file_id', image.file_id);
                              props.setValue('file', image);
                            }
                            setCollectionModalOpen(false);
                          }
                        }}
                      >
                        <CardActionArea>
                          <styles.spanType>{image.type}</styles.spanType>
                          <CardMedia
                            component="img"
                            height="140"
                            width="150"
                            image={image.reference}
                          />
                          <styles.span>{image.original_name}</styles.span>
                        </CardActionArea>
                        <styles.rowIcon>
                          <IconButton
                            color={'info'}
                            size={'small'}
                            onClick={event => {
                              event.stopPropagation();
                              downloadFile(
                                image.reference,
                                image.original_name,
                              );
                              cleanModalState();
                              setCollectionModalOpen(false);
                            }}
                          >
                            <FileDownload fontSize="medium" />
                          </IconButton>
                          <IconButton
                            color={'info'}
                            size={'small'}
                            onClick={event => {
                              event.stopPropagation();
                              setSkill({
                                ...refSkill.current,
                                content: undefined,
                              });
                              cleanModalState();
                              editCategory(image);
                              setCollectionModalOpen(false);
                            }}
                          >
                            <ModeEdit />
                          </IconButton>
                          <IconButton
                            color={'error'}
                            size={'small'}
                            onClick={event => {
                              event.stopPropagation();
                              deleteImage(image);
                              setCollectionModalOpen(false);
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </styles.rowIcon>
                      </Card>
                    );
                  })}
                </styles.row>
                <styles.row>
                  {images
                    .slice(images.length / 2, images.length)
                    .map((image, key) => {
                      return (
                        <Card
                          key={key}
                          sx={{
                            width: 150,
                            margin: 'auto',
                            marginTop: '0.5rem',
                          }}
                          onClick={event => {
                            event.stopPropagation();
                            cleanModalState();
                            if (
                              image.mime_type ===
                                'application/x-msdos-program' ||
                              image.mime_type === 'application/x-msdos-program'
                            ) {
                              toast({
                                message:
                                  'O tipo de arquivo não pode ser selecionado.',
                                type: 'warning',
                              });
                            } else {
                              if (image.categories.length === 0) {
                                addCategory(image);
                              } else {
                                setFileName(image.original_name);
                                setFileType(image.mime_type);
                                props.setValue('file_id', image.file_id);
                                props.setValue('file', image);
                              }
                              setCollectionModalOpen(false);
                            }
                          }}
                        >
                          <CardActionArea>
                            <styles.spanType>{image.type}</styles.spanType>
                            <CardMedia
                              component="img"
                              height="140"
                              width="150"
                              image={image.reference}
                            />
                            <styles.span>{image.original_name}</styles.span>
                            <styles.rowIcon>
                              <IconButton
                                color={'info'}
                                size={'small'}
                                onClick={event => {
                                  event.stopPropagation();
                                  //TODO: NECESSÁRIO REFATORAR TODO O COMPONENTE
                                  downloadFile(
                                    image.reference,
                                    image.original_name,
                                  );
                                  cleanModalState();
                                  setCollectionModalOpen(false);
                                }}
                              >
                                <FileDownload fontSize="medium" />
                              </IconButton>
                              <IconButton
                                color={'info'}
                                size={'small'}
                                onClick={event => {
                                  event.stopPropagation();
                                  cleanModalState();
                                  editCategory(image);
                                  setCollectionModalOpen(false);
                                }}
                              >
                                <ModeEdit />
                              </IconButton>
                              <IconButton
                                color={'error'}
                                size={'small'}
                                onClick={event => {
                                  event.stopPropagation();
                                  deleteImage(image);
                                  setCollectionModalOpen(false);
                                }}
                              >
                                <DeleteForever />
                              </IconButton>
                            </styles.rowIcon>
                          </CardActionArea>
                        </Card>
                      );
                    })}
                </styles.row>
              </>
            ) : (
              <>Carregando imagens ...</>
            )}

            <styles.row>
              <div style={{ margin: 'auto', marginTop: '0.5rem' }}>
                <Button
                  variant="contained"
                  disabled={imagePage === 1}
                  onClick={event => {
                    event.stopPropagation();
                    if (imagePage > 0) {
                      setLoading(true);
                      const params: IGetFilesParams = {
                        offset: imagePage === 1 ? imagePage : imagePage - 1,
                        permission: props.permission,
                        type: typeFilter,
                        search: filter,
                        limit: 6,
                      };

                      if (params['search'] === '') delete params['search'];
                      if (params['type'] === '') delete params['type'];

                      getFiles(params).then(images => {
                        setImages(images?.data);
                        imagePage === 1 && setImagePage(imagePage - 1);
                      });

                      setLoading(false);
                    }
                  }}
                >
                  {'<'}
                </Button>
                <Button variant="contained">{imagePage}</Button>
                <Button
                  variant="contained"
                  onClick={event => {
                    setLoading(true);
                    const params: IGetFilesParams = {
                      offset: imagePage + 1,
                      permission: props.permission,
                      type: typeFilter,
                      search: filter,
                      limit: 6,
                    };

                    if (params['search'] === '') delete params['search'];
                    if (params['type'] === '') delete params['type'];

                    getFiles(params)
                      .then(images => {
                        if (images?.data.length > 0) {
                          setImages(images?.data);
                          setImagePage(imagePage + 1);
                        } else {
                          toast({
                            message: 'Última página',
                            type: 'warning',
                          });
                        }
                      })
                      .catch(error => {
                        toast({
                          message: error.message,
                          type: 'error',
                        });
                      });

                    setLoading(false);
                    event.stopPropagation();
                  }}
                >
                  {'>'}
                </Button>
              </div>
            </styles.row>
          </styles.column>
        </Box>
      </Modal>
      {props.type !== 'author' && (
        <Box
          component="div"
          sx={{
            '& .MuiTextField-root': { m: 0, width: '100%' },
          }}
        >
          <styles.Label>Permitir Download</styles.Label>
          <Controller
            name="allow_download"
            control={props.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <Checkbox
                    checked={allowDownload}
                    value={allowDownload}
                    onChange={(event: any) => {
                      setAllowDownload(event.target.checked);
                      onChange(event);
                    }}
                  />
                </>
              );
            }}
          />
        </Box>
      )}
      {((files && files[0].type === 'application/pdf') ||
        fileType === 'application/pdf') &&
        props.type !== 'podcast' && (
          <Box
            component="div"
            sx={{
              '& .MuiTextField-root': { m: 0, width: '100%' },
            }}
          >
            <styles.Label>Permitir Leitura</styles.Label>
            <Controller
              name="show_page_flip"
              control={props.control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <Checkbox
                      checked={showPageFlip}
                      value={showPageFlip}
                      onChange={(event: any) => {
                        setShowPageFlip(event.target.checked);
                        onChange(event);
                      }}
                    />
                  </>
                );
              }}
            />
          </Box>
        )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <AppBar
            position="static"
            style={{ marginBottom: '1rem' }}
            sx={{ bgcolor: '#008d4c', height: '3.5rem' }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Mídia
              </Typography>
              <Button onClick={() => setModalOpen(false)} color="inherit">
                x
              </Button>
            </Toolbar>
          </AppBar>

          <styles.Label>
            <p>Arquivo/Referência *</p>
          </styles.Label>
          {modalPreview !== undefined ? (
            <styles.ContentImg>
              <styles.Preview
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                src={modalPreview || ''}
              />
            </styles.ContentImg>
          ) : (
            <ArticleIcon
              color="disabled"
              fontSize="large"
              sx={{ height: '6em' }}
              style={{ marginLeft: 'auto', marginRight: 'auto', width: '6em' }}
            />
          )}
          <styles.ContentSeletor>
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 0, width: '100%' },
              }}
            >
              <styles.Label>Nome Arquivo</styles.Label>
              <Controller
                name="file_name"
                control={props.control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <styles.InputText
                        disabled={props.view}
                        name="file_name"
                        value={fileName}
                        onChange={event => {
                          setFileName(event.target.value);
                        }}
                        required
                        id="outlined-required"
                      />
                    </>
                  );
                }}
              />
            </Box>
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 0, width: '100%' },
              }}
            >
              <styles.Label>Tooltip Arquivo</styles.Label>
              <Controller
                name="file_toltip"
                control={props.control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <styles.InputText
                      disabled={props.view}
                      name="file_toltip"
                      value={fileToolTip}
                      onChange={event => {
                        setFileToolTip(event.target.value);
                      }}
                      required
                      id="outlined-required"
                    />
                  </>
                )}
              />
            </Box>
            {files && (
              <CategoryDropDown
                setBackCategories={setBackCategories}
                backCategories={backCategories}
                formProps={skill}
              />
            )}
          </styles.ContentSeletor>
          <ButtonGroup
            variant="contained"
            size="small"
            style={{ margin: 'auto', marginBottom: '1rem' }}
          >
            <label htmlFor="contained-button-file">
              <Input
                accept="*/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(event: any) => {
                  if (
                    event.target.files[0].type === 'application/x-msdownload' ||
                    event.target.files[0].type ===
                      'application/x-msdos-program' ||
                    event.target.files[0].type === 'application/octet-stream'
                  ) {
                    toast({
                      message: 'O tipo de arquivo não pode ser selecionado.',
                      type: 'warning',
                    });
                  } else {
                    setFiles(event.target.files);
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onloadend = (e: any) => {
                      setModalPreview(
                        reader.result != undefined ? reader.result : 'null',
                      );
                    };
                  }
                }}
              />
              <Button
                variant="contained"
                size="small"
                component="span"
                startIcon={<Attachment />}
                style={{
                  backgroundColor: '#00acd6',
                  marginLeft: '1rem',
                }}
              >
                <span>Escolher arquivo</span>
              </Button>
            </label>
            <Button
              startIcon={<Save />}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#008d4c',
                marginLeft: 10,
              }}
            >
              <span>Salvar</span>
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>
    </div>
  );
}

import { CancelToken } from 'axios';
import Swal from 'sweetalert2';

import { validationMethodology } from '../common/validations/methodolyValidation';
import httpClient from '../http-client';
import { httpClientSebrae } from '../http-client/apiSebrae';
import {
  ICourse,
  ICourseContent,
  IMethodology,
  ISyncCourseParams,
  ISyncCourseStepFour,
  ISyncCourseStepOne,
  ISyncCourseStepThree,
  ISyncCourseStepTwo,
} from '../models/SyncCourse';

interface ICourseSyncResponse {
  data: ICourseContent[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

const codPrograma: string = process.env.REACT_APP_SEBRAE_COD_PROGRAMA || '888';

export const getMethodologyByCode = async (
  codMethodology: string,
): Promise<IMethodology> => {
  const response = await httpClientSebrae
    .get<IMethodology>(
      `/v1/event/${codPrograma}/cod_programa/${codMethodology}/cod_methodology`,
    )
    .then(res => res.data)
    .catch(function (error) {
      console.error(error);
      if (error.response?.status === 404) {
        throw new Error(error.response.data.message);
      } else if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Erro na comunicação com o barramento.');
      }
    });

  validationMethodology(response);

  return response;
};

export const createSyncCourse = async (data: ICourse) => {
  const course = await httpClient
    .post('/contents/course-sync', data)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota não existe!');
      }

      if (error.response.status === 400) {
        throw new Error(error.response.data.message);
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const ListSyncCourse = async (params?: ISyncCourseParams) => {
  const courses = await httpClient
    .get<ICourseSyncResponse>('/content/admin/list?types[]=course_sync', {
      params,
    })
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return courses;
};

export const GetSyncCourseById = async (
  id: string,
): Promise<ICourseContent> => {
  const course = await httpClient
    .get(`/contents/course-sync/${id}`)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const UpdateSyncCourseById = async (
  id: string,
  data,
): Promise<ICourseContent> => {
  const course = await httpClient
    .put(`/contents/course-sync/${id}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Rota ou curso não existe!',
        });
        throw new Error('Rota ou curso não existe!');
      }

      if (error.response.status === 400) {
        throw new Error(error.response.data.message);
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const deleteSyncCourse = async (id: string): Promise<any> => {
  const course = await httpClient
    .delete(`/content/${id}`)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const getSyncModule = async () => {
  const module = await httpClient
    .get('/module-sync')
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return module.data;
};

export const getSyncCourseStepOne = async (
  id: string,
  cancelToken: CancelToken,
): Promise<ISyncCourseStepOne> => {
  const course = await httpClient
    .get(`/contents/course-sync/step-one/${id}`, { cancelToken })
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const getSyncCourseStepTwo = async (
  id: string,
): Promise<ISyncCourseStepTwo> => {
  const course = await httpClient
    .get(`/contents/course-sync/step-two/${id}`)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const getSyncCourseStepThree = async (
  id: string,
): Promise<ISyncCourseStepThree> => {
  const course = await httpClient
    .get(`/contents/course-sync/step-three/${id}`)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const getSyncCourseStepFour = async (
  id: string,
): Promise<ISyncCourseStepFour> => {
  const course = await httpClient
    .get(`/contents/course-sync/step-four/${id}`)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const updateSyncCourseStepOne = async (
  id: string,
  data,
): Promise<ISyncCourseStepOne> => {
  const course = await httpClient
    .put(`/contents/course-sync/step-one/${id}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const updateSyncCourseStepTwo = async (
  id: string,
  data,
): Promise<ISyncCourseStepTwo> => {
  const course = await httpClient
    .put(`/contents/course-sync/step-two/${id}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const updateSyncCourseStepThree = async (
  id: string,
  data,
): Promise<ISyncCourseStepThree> => {
  const course = await httpClient
    .put(`/contents/course-sync/step-three/${id}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

export const updateSyncCourseStepFour = async (
  id: string,
  data,
): Promise<ISyncCourseStepFour> => {
  const course = await httpClient
    .put(`/contents/course-sync/step-four/${id}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return course;
};

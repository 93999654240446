import { Edit, Repeat } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import Pills from '../../../components/Pills';
import { useGetReport, useTablePagination } from '../../../hooks';
import {
  getListConsumption,
  resendConsumption,
} from '../../../services/consumption';
import ConsumptionTemplate from '../../../templates/ReportsTemplates/ConsumptionTemplate';
import { formatCpf, removeTraitPoints } from '../../../utils';

export default function ConsumptionRecord() {
  const [content, setContent] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const state = {
    user_acess: 'all',
    situation: 'all',
  };

  const methods = useForm({
    defaultValues: state,
  });

  const {
    paginationModel,
    rowCount,
    setRowCount,
    changePage,
    changePageSize,
    setPaginationModel,
  } = useTablePagination(25);

  const { listConsumption, loading } = useGetReport(getListConsumption);

  const consumptionTypes = {
    download: 'Download',
    player: 'Player',
    reading: 'Leitura',
    teaser: 'Video Teaser de Curs',
    conclusion: 'Finalizados de curso/serie',
    course_sync: 'Curso ao Vivo',
  };

  const handleSituationColor = row => {
    switch (true) {
      case row.interaction && row.interaction_saved === false:
        return 'yellow';
      case row.interaction === false:
        return 'red';
      case row.interaction_saved:
        return 'green';
      default:
        return 'neutral';
    }
  };

  const resend = async (consumptionId: string) => {
    try {
      await resendConsumption(consumptionId).then(() => {
        toast.success('Consumo editado com sucesso!');
        methods.handleSubmit(data =>
          handlePagination(data, paginationModel.page),
        )();
      });
    } catch (error: any) {
      console.error(error);
      toast.error(error.message || error);
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: 'Data',
        field: 'created_at',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          format(new Date(row.created_at), 'dd/MM/yyyy'),
      },
      {
        headerName: 'CPF do Usuário',
        field: 'cpf',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => (row.user ? formatCpf(row.user.cpf) : null),
      },
      {
        headerName: 'Título',
        field: 'title',
        flex: 2,
        sortable: false,
        renderCell: cellValues => (
          <p style={{ whiteSpace: 'normal' }}>
            {cellValues.row?.content.title}
          </p>
        ),
      },
      {
        headerName: 'Tipo Conteúdo',
        field: 'type_content',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => row.content.type,
      },
      {
        headerName: 'Tipo Consumo',
        field: 'type',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => consumptionTypes[row.type],
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => {
          switch (true) {
            case row.interaction && row.interaction_saved === false:
              return 'Válido com erro';
            case row.interaction === false:
              return 'Inválido';
            case row.interaction_saved:
              return 'Válido com sucesso';
            default:
              return null;
          }
        },
        renderCell: cellValues => (
          <Pills color={handleSituationColor(cellValues.row)}>
            {cellValues?.value}
          </Pills>
        ),
      },
      {
        headerName: 'Mensagem de Erro',
        field: 'message',
        flex: 2,
        sortable: false,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Ações',
        field: 'action',
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Button
              onClick={() =>
                navigate(`/reports/consumption-record/edit`, {
                  state: {
                    consumption_id: cellValues.row['consumption_id'],
                    content_type: cellValues.row['content'].type,
                    filters: methods.getValues(),
                  },
                })
              }
              icon={<Edit fontSize="inherit" />}
              color="secondary"
              title="Editar consumo"
            />
            <Button
              onClick={() => resend(cellValues.row['consumption_id'])}
              icon={<Repeat fontSize="inherit" />}
              color="secondary"
              title="Reenviar consumo"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const handleData = data => {
    if (!data['cpf'].length) delete data['cpf'];

    if (data['cpf']) data['cpf'] = removeTraitPoints(data['cpf']);
    if (data['contents']?.length)
      data['contents'] = data['contents'].map(content => content.value);

    if (data['created_start_at'])
      data['created_start_at'] = data['created_start_at'].setHours(0, 0, 0, 0);
    if (data['created_end_at'])
      data['created_end_at'] = data['created_end_at'].setHours(23, 59, 59, 999);

    return data;
  };

  const submit = async data => {
    const obj = handleData(data);

    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    listConsumption(setContent, setRowCount, {
      limit: paginationModel.pageSize,
      offset: 1,
      ...obj,
    });
  };

  const handlePagination = async (data, page) => {
    const obj = handleData(data);

    listConsumption(setContent, setRowCount, {
      limit: paginationModel.pageSize,
      offset: page,
      ...obj,
    });
  };

  const handleClearForm = () => {
    methods.reset(state);
    location.state = null;
    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    listConsumption(setContent, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      ...state,
    });
  };

  const tableProps = {
    keyId: 'consumption_id',
    columns: columns,
    rows: content,
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [5, 10, 15, 20, 25],
    pageSize: paginationModel.pageSize,
    disableColumnReorder: true,
    rowCount,
    loading,
  };

  useEffect(() => {
    const state = location.state as { filters: any };
    if (state) {
      methods.reset(state.filters);
      submit(state.filters);
    }
  }, []);

  useEffect(() => {
    methods.handleSubmit(data =>
      handlePagination(data, paginationModel.page),
    )();
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <FormProvider {...methods}>
      <ConsumptionTemplate
        submit={submit}
        clearForm={handleClearForm}
        tableProps={tableProps}
      />
    </FormProvider>
  );
}

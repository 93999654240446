import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Loading from '../../../components/Loading';
import { ReportFromResponse } from '../../../models/from-api-response/report';
import { getReports } from '../../../services/reports';
import GeneralReportTemplate from '../../../templates/ReportsTemplates/General';

export default function General() {
  const [reports, setReports] = useState<ReportFromResponse>({
    classUsersFinished: 0,
    classUsersInProgress: 0,
    classUsersNotCanceledCount: 0,
    classUsersSubscribed: 0,
    consumption: 0,
    consumptionBus: 0,
    consumptionIntegrationErrorNoResend: 0,
    consumptionIntegrationErrorResend: 0,
    consumptionValid: 0,
    consumptionWithUser: 0,
    contentUsersFinished: 0,
    contentUsersInProgress: 0,
    contentUsersSubscribed: 0,
    subscribedButNotEnoughAttendance: 0,
    users: 0,
    usersWhoCanceledCount: 0,
  });
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);
    const localContents = await getReports();
    setReports(localContents);
    setLoading(false);
  };

  useEffect(() => {
    getReport();
  }, []);

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="reports">Relatórios</span>,
    <span key="report-general">Relatório Geral</span>,
  ];

  if (loading) <Loading />;

  return <GeneralReportTemplate reports={reports} breadcrumbs={breadcrumbs} />;
}

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
    width: 100%;
  `}
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid;
    border-color: ${'hsl(0, 0%, 80%)'};
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    color: ${theme.colors.neutral_600};
    font-size: ${theme.font.sizes.xsmall};
    padding: 0.625rem 0.75rem;
    position: relative;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;
    width: 17rem;

    &::placeholder {
      color: ${theme.colors.neutral_600};
      font-style: italic;
    }

    :focus {
      border-color: ${theme.colors.default};
      box-shadow: 0 0 0 1px ${theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    :hover {
      border-color: hsl(0, 0%, 70%);
    }

    :disabled {
      background-color: ${theme.colors.gray_200};
    }
  `}
`;

import React from 'react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { FlexContainer } from '../../../components/FlexContainer';
import Heading from '../../../components/Heading';
import { ReportFromResponse } from '../../../models/from-api-response/report';
import * as S from './styles';

type GeneralReportProps = {
  reports: ReportFromResponse;
  breadcrumbs: React.ReactElement[];
};
export default function GeneralReportTemplate({
  reports,
  breadcrumbs,
}: GeneralReportProps) {
  const syncCourseTooltip = {
    registered:
      'Pessoas que estão inscritas em dado curso e ainda não foi iniciado',
    inProgress:
      'Pessoas que estão inscritas em dado curso e o mesmo ainda encontra-se acontecendo',
    notCompleted:
      'Pessoas que se inscreveram, mas não atingiram a presença necessária',
    concluded:
      'Pessoas que se inscreveram, e atingiram a presença necessária (emite certificado)',
    sales: 'Número de compras realizadas, exceto os cancelados',
    canceledSales: 'Número de pessoas que compraram e cancelaram',
  };

  return (
    <S.Wrapper>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <FlexContainer>
        <S.Card>
          <S.CardBody>
            <Heading
              level={2}
              size="xsmall"
              color="neutral_500"
              lineHeight={'1.5rem'}
            >
              TOTAL USUÁRIOS
            </Heading>
            <S.CardItemText fontWeight="semiBold" fontSize="medium">
              {reports.users}
            </S.CardItemText>
          </S.CardBody>
        </S.Card>
      </FlexContainer>

      <FlexContainer flexWrap="wrap" gap="1rem" width="100%">
        <S.Card fullWidth>
          <S.CardHeader>
            <Heading
              level={2}
              size="medium"
              color="neutral_900"
              lineHeight={'1.5rem'}
            >
              Consumo
            </Heading>
          </S.CardHeader>

          <S.CardBody>
            <FlexContainer gap="1rem" width="100%" flexWrap="wrap">
              <FlexContainer flexDirection="column" flex="1">
                <S.CardItem>
                  <S.CardItemText color="neutral_700">Total</S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumption}
                  </S.CardItemText>
                </S.CardItem>

                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Total - Inválido
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumption - reports.consumptionValid}
                  </S.CardItemText>
                </S.CardItem>

                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Total - Válido
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumptionValid -
                      reports.consumptionIntegrationErrorNoResend}
                  </S.CardItemText>
                </S.CardItem>

                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Barramento
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumptionBus}
                  </S.CardItemText>
                </S.CardItem>
              </FlexContainer>

              <FlexContainer flexDirection="column" flex="1">
                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Consumo Válido com Erro - Sem recuperação
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumptionIntegrationErrorNoResend}
                  </S.CardItemText>
                </S.CardItem>

                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Consumo Válido com Erro - Necessário reenvio
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumptionIntegrationErrorResend}
                  </S.CardItemText>
                </S.CardItem>

                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Consumo usuário logado
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumptionWithUser}
                  </S.CardItemText>
                </S.CardItem>

                <S.CardItem>
                  <S.CardItemText color="neutral_700">
                    Consumo usuário não logado
                  </S.CardItemText>
                  <S.Divider />
                  <S.CardItemText color="neutral_700">
                    {reports.consumption - reports.consumptionWithUser}
                  </S.CardItemText>
                </S.CardItem>
              </FlexContainer>
            </FlexContainer>
          </S.CardBody>
        </S.Card>
      </FlexContainer>

      <FlexContainer flexWrap="wrap" gap="1rem">
        <S.Container>
          <S.Card>
            <S.CardHeader>
              <Heading
                level={2}
                size="medium"
                color="neutral_900"
                lineHeight={'1.5rem'}
              >
                Cursos e séries
              </Heading>
            </S.CardHeader>

            <S.CardBody>
              <S.CardItem>
                <S.CardItemText color="neutral_700"> Inscritos</S.CardItemText>
                <S.Divider />
                <S.CardItemText color="neutral_700">
                  {reports.contentUsersSubscribed}
                </S.CardItemText>
              </S.CardItem>

              <S.CardItem>
                <S.CardItemText color="neutral_700">
                  Em progresso
                </S.CardItemText>
                <S.Divider />
                <S.CardItemText color="neutral_700">
                  {reports.contentUsersInProgress}
                </S.CardItemText>
              </S.CardItem>

              <S.CardItem>
                <S.CardItemText color="neutral_700">Concluído</S.CardItemText>
                <S.Divider />
                <S.CardItemText color="neutral_700">
                  {reports.contentUsersFinished}
                </S.CardItemText>
              </S.CardItem>
            </S.CardBody>
          </S.Card>
        </S.Container>

        <S.Container>
          <S.Card>
            <S.CardHeader>
              <Heading
                level={2}
                size="medium"
                color="neutral_900"
                lineHeight={'1.5rem'}
              >
                Curso ao Vivo
              </Heading>
            </S.CardHeader>

            <S.CardBody>
              <FlexContainer gap="1rem" width="100%" flexWrap="wrap">
                <FlexContainer flexDirection="column" flex="1">
                  <S.CardItem>
                    <S.CardItemText
                      color="neutral_700"
                      title={syncCourseTooltip.registered}
                    >
                      Inscritos
                    </S.CardItemText>
                    <S.Divider />
                    <S.CardItemText color="neutral_700">
                      {reports.classUsersSubscribed}
                    </S.CardItemText>
                  </S.CardItem>

                  <S.CardItem>
                    <S.CardItemText
                      color="neutral_700"
                      title={syncCourseTooltip.inProgress}
                    >
                      Em progresso
                    </S.CardItemText>
                    <S.Divider />
                    <S.CardItemText color="neutral_700">
                      {reports.classUsersInProgress}
                    </S.CardItemText>
                  </S.CardItem>

                  <S.CardItem>
                    <S.CardItemText
                      color="neutral_700"
                      title={syncCourseTooltip.notCompleted}
                    >
                      Não Concluído
                    </S.CardItemText>
                    <S.Divider />
                    <S.CardItemText color="neutral_700">
                      {reports.subscribedButNotEnoughAttendance}
                    </S.CardItemText>
                  </S.CardItem>
                </FlexContainer>

                <FlexContainer flexDirection="column" flex="1">
                  <S.CardItem>
                    <S.CardItemText
                      color="neutral_700"
                      title={syncCourseTooltip.concluded}
                    >
                      Concluído
                    </S.CardItemText>
                    <S.Divider />
                    <S.CardItemText color="neutral_700">
                      {reports.classUsersFinished}
                    </S.CardItemText>
                  </S.CardItem>

                  <S.CardItem>
                    <S.CardItemText
                      color="neutral_700"
                      title={syncCourseTooltip.sales}
                    >
                      Vendas
                    </S.CardItemText>
                    <S.Divider />
                    <S.CardItemText color="neutral_700">
                      {reports.classUsersSubscribed}
                    </S.CardItemText>
                  </S.CardItem>

                  <S.CardItem>
                    <S.CardItemText
                      color="neutral_700"
                      title={syncCourseTooltip.canceledSales}
                    >
                      Vendas Canceladas
                    </S.CardItemText>
                    <S.Divider />
                    <S.CardItemText color="neutral_700">
                      {reports.usersWhoCanceledCount}
                    </S.CardItemText>
                  </S.CardItem>
                </FlexContainer>
              </FlexContainer>
            </S.CardBody>
          </S.Card>
        </S.Container>
      </FlexContainer>
    </S.Wrapper>
  );
}

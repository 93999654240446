const convertTimeToMilliseconds = (time: string | null): number => {
  if (time === null) return 0;

  const timeArray = time.split(':');
  const hrs = Number(timeArray[0]) * 60 * 60 * 1000;
  const mins = Number(timeArray[1]) * 60 * 1000;
  const secs = Number(timeArray[2]) * 1000;

  const milliseconds = hrs + mins + secs;

  return milliseconds;
};

export default convertTimeToMilliseconds;

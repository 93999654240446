import React from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { Option } from 'react-select/src/filters';

import CustomSelect from '../../components/CustomSelect';
import { FlexContainer } from '../../components/FlexContainer';
import MainFile from '../../components/FormComponent/MainFile';
import TextField from '../../components/FormComponent/TextField';
import BasicContentTemplate, {
  BasicContentTemplateType,
} from '../BasicContentTemplate';

type MagazineTemplateType = BasicContentTemplateType;

export default function MagazineTemplate({
  title,
  view,
  content,
  isLoading,
  submit,
}: MagazineTemplateType) {
  const {
    setValue,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useFormContext();

  const optionsToSelect = [
    { label: 'Diário', value: '' },
    { label: 'Semanal', value: '' },
    { label: 'Quinzenal', value: '' },
    { label: 'Mensal', value: '' },
    { label: 'Bimestral', value: '' },
    { label: 'Trimestral', value: '' },
    { label: 'Anual', value: '' },
  ];

  const handleSelectChange = {
    'select-option': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    'remove-value': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    clear: (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
  };

  return (
    <BasicContentTemplate
      title={title}
      view={view}
      submit={submit}
      content={content}
      isLoading={isLoading}
    >
      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <TextField
          label="Ano"
          name="years"
          disabled={view}
          flex="1 0 13.75rem"
        />
        <TextField
          label="Editora"
          name="publishing_company"
          disabled={view}
          flex="1 0 13.75rem"
        />
        <TextField
          label="Periodicidade"
          name="frequency"
          flex="1 0 13.75rem"
          required
        >
          <CustomSelect
            id="frequency"
            isClearable
            isRtl={false}
            isSearchable
            options={optionsToSelect}
            onChange={(value, action) => {
              handleSelectChange[action.action]('frequency', value);
              value && setValue('frequency', value.value);
            }}
            value={optionsToSelect.find(
              requester => requester.value === watch('frequency'),
            )}
            placeholder="Selecione a periodicidade"
            thereIsError={Object.keys(errors).includes('frequency')}
            isDisabled={view}
          />
        </TextField>
        <TextField
          label="Extensão"
          name="extension"
          disabled={view}
          flex="1 0 13.75rem"
        />
      </FlexContainer>

      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <MainFile
          getValues={getValues}
          titleCompany={'Arquivo'}
          setValue={setValue}
          control={control}
          content={content}
          view={view}
          permission="public"
        />
      </FlexContainer>
    </BasicContentTemplate>
  );
}
